<template>
    <div class="max-w-2xl mx-auto opacity-10">
        <div class="bg-primary-600 mb-[1px] w-full h-32 animate-pulse"></div>
        <div class="grid grid-cols-4 gap-[1px] h-28 mb-[1px]">
            <div class="bg-primary-600 mb-[1px] w-full animate-pulse"></div>
            <div class="bg-primary-600 mb-[1px] w-full animate-pulse"></div>
            <div class="bg-primary-600 mb-[1px] w-full animate-pulse"></div>
            <div class="bg-primary-600 mb-[1px] w-full animate-pulse"></div>
        </div>
        <div class="bg-primary-600 mb-[1px] h-20 w-full animate-pulse"></div>
        <div class="bg-primary-600 mb-[1px] h-[800px] w-full animate-pulse"></div>
    </div>
</template>