import { call } from './client'

export const createUser = async (user) => {
    return await call('POST', '/user', user)
}

export const updateUser = async (auth0Id, userData) => {
    return await call('PUT', `/user/${encodeURI(auth0Id)}`, userData)
}

export const getUserByAuth0Id = async (auth0Id) => {
    return await call('GET', `/user/${encodeURI(auth0Id)}`)
}

export const getApplicationForUser = async (auth0Id) => {
    return await call('GET', `/user/${encodeURI(auth0Id)}/application`)
}

export const createApplicationForUser = async (auth0Id) => {
    return await call('POST', `/user/${encodeURI(auth0Id)}/application`)
}

export const updateApplicationForUser = async (auth0Id, application) => {
    return await call('PUT', `/user/${encodeURI(auth0Id)}/application`, application, {
        keepalive: true
    })
}