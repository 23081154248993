<template>
    <QuestionnaireSection title="Annuity funding calculator"
        helperText="To help determine the optimal investment amount for your annuity, please estimate your total liquid assets."
        @click="nextStep" :disableNext="!store.liquidFunds" :enableEnterKey="false">
        <div class="mb-12 sm:mb-8 space-y-6">
            <div>
                <MoneyField label="What are your total estimated liquid assets?" :value="store.liquidFunds"
                    @input="v => store.liquidFunds = v" />
            </div>
            <div class="grid grid-cols-2 gap-4">
                <ul class="flex flex-col text-xs text-left gap-x-2 gap-y-0.5 sm:gap-x-4 sm:gap-y-3">
                    <li v-for="asset in includedAssets" class="flex gap-x-0.5 ">
                        <CheckCircleIcon class="h-3 w-3 mt-0.5 text-green-600" /> {{ asset }}
                    </li>
                </ul>
                <ul class="flex flex-col text-xs text-left gap-x-2 gap-y-0.5 sm:gap-x-4 sm:gap-y-3">
                    <li v-for="asset in excludedAssets" class="flex gap-x-0.5 ">
                        <XCircleIcon class="h-3 w-3 mt-0.5 text-red-600" /> {{ asset }}
                    </li>
                </ul>
            </div>
        </div>
    </QuestionnaireSection>
    <div class="w-full text-center">
        <button class="px-4 py-2 text-sm underline" @click="helpModalOpen = true">I need some help</button>
    </div>
    <ContactModal :open="helpModalOpen" @close="helpModalOpen = false" :name="`${store?.firstName} ${store?.lastName}`"
        :email="store?.email" :phone="`+1${store?.phone}`" calLink="revise/app-call" />
</template>

<script setup>
import { onMounted } from 'vue';
import { MoneyField } from '@reviseannuity/randolph-ui';
import QuestionnaireSection from '../questionnaireSections/QuestionnaireSection.vue';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid';
import { useRegistrationStore, REGISTRATION_STEPS } from '../../stores/registrationStore';
import { event } from 'vue-gtag';
import ContactModal from '../ContactModal.vue';
import { ref } from 'vue';

const store = useRegistrationStore()

const helpModalOpen = ref(false)
const includedAssets = ["Checking and savings", "Money market accounts", "Brokerage accounts", "CDs"];
const excludedAssets = ["Real estate", "Limited partnerships", "Collectibles", "401(k) or IRA"];

onMounted(() => {
    event('page_view', {
        page_title: 'Master App Registration - Liquid Funds',
        page_location: window.location.href,
        page_path: window.location.pathname
    })

    // updateQuestionnaireResults()
})

const nextStep = () => {
    store.setActiveStep(REGISTRATION_STEPS.INVESTMENT_AMOUNT)
}
</script>
