import { call } from "./client"

export const getAllStrategies = async ({ state, age, riskTolerance, investmentAmount, focus }) => {
    const params = new URLSearchParams()
    if (state) params.append('state', state)
    if (age) params.append('age', age)
    if (riskTolerance) params.append('riskTolerance', riskTolerance)
    if (investmentAmount) params.append('investmentAmount', investmentAmount)
    if (focus) params.append('focus', focus)

    return call('GET', `/strategy?${params.toString()}`)
}

export const getStrategy = async (id) => {
    return call('GET', `/strategy/${id}`)
}