<template>
    <div class="h-screen w-screen bg-gray-50 text-gray-500 flex justify-center items-center">Logging you in...</div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useApplicationStore } from '../stores/applicationStore';
import { onMounted } from 'vue';

const router = useRouter();
const store = useApplicationStore()

onMounted(async () => {
    if (store.user.auth0_id && store.user.email && store.user.funding_type && store.user.funding_amount > 0) {
        if (store?.user?.funding_type !== 'after-tax' && !store?.user?.rollover_call) {
            router.push({ name: 'register' });
        } else {
            router.push({ name: 'apply' });
        }
    } else {
        router.push({ name: 'register' });
    }
})
</script>