<template>
    <SDPWrapper thin>
        <Accordion>
            <AccordionItem title="Technical Strategy Specifications"
                @click="v => v ? accordionOpened('Technical Strategy Specifications') : null">
                <DetailsTable :rows="technicalStrategySpecifications" />
            </AccordionItem>
            <AccordionItem title="Carrier Details" @click="v => v ? accordionOpened('Carrier Details') : null">
                <img :src="strategy?.underlyingProduct?.insurer?.logo[0].url" class="mb-4 w-16" />
                <h3 class="text-xl font-bold font-serif mb-2">{{ strategy.underlyingProduct.insurer.name }}</h3>
                <p class="font-semibold text-sm text-primary-600 mb-4">{{ strategy.underlyingProduct.insurer.rating
                    }}
                    Rated</p>
                <div class="text-sm" v-html="strategy.underlyingProduct.insurer.description" />
            </AccordionItem>
            <AccordionItem title="Underlying Product Details"
                @click="v => v ? accordionOpened('Underlying Product Details') : null">
                <h3 class="text-xl font-bold font-serif mb-2">{{ strategy.underlyingProduct.name }}</h3>
                <div v-html="strategy.underlyingProduct.description" class="formatHtml mb-8" />
                <a v-if="strategy?.underlyingProduct?.brochure" :href="strategy.underlyingProduct.brochure[0].url"
                    class="text-primary-600 underline text-sm flex gap-1 items-center">
                    <ArrowDownTrayIcon class="h-3.5 w-3.5 inline-block" />Download product brochure
                </a>
            </AccordionItem>
            <AccordionItem title="Fee Transparency" id="feeTransparency"
                @click="v => v ? accordionOpened('Fee Transparency') : null">
                <div class="formatHtml">
                    <p class="mb-6">Revise is committed to being radically transparent about all fees
                        associated with
                        our strategies.
                    </p>
                </div>
                <DetailsTable :rows="[
                    { key: 'Strategy Fee', value: `${formatPercentage(strategy.strategyFee)}%` },
                    { key: 'Product Fee', value: `${formatPercentage(strategy.productFee)}%` },
                    {
                        key: 'Early Surrender Fees', value: `<p class='mb-2'>During the surrender charge
                        period, a surrender charge is assessed on any amount withdrawn, whether as a partial or full
                        surrender, that exceeds the penalty-free allowance. Early surrender fees vary by state and
                        product.</p>
                        <p>Example early surrender fees, from year 1 to 10 (assuming a 10 year annuity): 8.5%, 8.5%, 8%,
                            7%, 6%, 5%, 4%, 3%, 2%, 1%</p>`
                    }]" />
            </AccordionItem>
            <AccordionItem title="Annuity Taxation" @click="v => v ? accordionOpened('Annuity Taxation') : null">
                <div class="formatHtml">
                    <p>All annuities are tax-deferred, meaning you’re not until funds are withdrawn or distributed
                        as
                        income. During a withdrawal, annuities are taxed differently based on whether they were
                        funded
                        with qualified (pre-tax) or non-qualified (post-tax) dollars.</p>
                    <div class="font-bold mb-2">Qualified Annuities (Pre-Tax)</div>
                    <ul>
                        <li>Funding: These are funded with pre-tax dollars, often through a direct rollover from a
                            401(k) or IRA.
                        </li>
                        <li>
                            Taxation: Withdrawals are taxed as ordinary income at your current tax rate.
                        </li>
                        <li>Contribution Limits: $7,000 annually if you’re under 50, and $8,000 annually if you’re
                            50 or
                            older (includes a $1,000 catch-up contribution).
                        </li>
                        <li>Early Withdrawals: Withdrawals before age 59½ incur a 10% penalty plus income taxes,
                            with
                            some exceptions like first-time home purchase, qualified education expenses, or
                            substantial
                            medical costs.</li>
                        <li>Required Minimum Distributions (RMDs): Start at age 72 (or age 73 starting in 2023 if
                            you
                            turn 72 on or after January 1, 2023).
                        </li>
                    </ul>
                    <div class="font-bold mb-2">Non-Qualified Annuities (Post-Tax)
                    </div>
                    <ul>
                        <li>Funding: These are funded with after-tax dollars, commonly from a checking, savings, or
                            taxable brokerage account.
                        </li>
                        <li>Taxation: You only pay income tax on the gains portion from your annuity in the year of
                            distribution, since you’ve already paid tax on the premium.
                        </li>
                    </ul>
                </div>
                <div class="disclaimer">
                    <p>The information provided on this website is not intended as specific tax or legal advice and
                        Revise Annuity employees are not authorized to provide tax or legal advice. You are
                        encouraged
                        to seek advice from your own tax or legal counsel.
                    </p>
                </div>
            </AccordionItem>
        </Accordion>
    </SDPWrapper>
</template>

<script setup>
import SDPWrapper from './SDPWrapper.vue';
import { Accordion, AccordionItem, DetailsTable } from '@reviseannuity/randolph-ui';
import { ArrowDownTrayIcon } from '@heroicons/vue/20/solid';
import { formatPercentage, formatMoney } from '../../utils';
import { ref } from 'vue';
import { onMounted } from 'vue';
import { event } from 'vue-gtag';

const { strategy } = defineProps({
    strategy: Object,
})

const technicalStrategySpecifications = ref(null)

onMounted(() => {
    let indices = []
    let specs = []

    specs.push({ key: 'Carrier', value: strategy.underlyingProduct.insurer.name })
    specs.push({ key: 'Product', value: strategy.underlyingProduct.name })

    if (strategy?.break) {
        specs.push({ key: 'Low Band Investment', value: `${formatMoney(strategy.minimumInvestment)} - ${formatMoney(strategy.break - 1)}` })
        specs.push({ key: 'High Band Investment', value: `${formatMoney(strategy.break)} - ${formatMoney(strategy.maximumInvestment)}` })
    } else {
        specs.push({ key: 'Minimum/Maximum Investment', value: `${formatMoney(strategy.minimumInvestment)} - ${formatMoney(strategy.maximumInvestment)}` })
    }

    specs.push({ key: 'Crediting Strategy', value: strategy.crediting })

    if (strategy?.indices && strategy.indices.length) {
        if (strategy?.indices?.length > 1) {
            specs.push({
                key: 'Indices', value: strategy.indices.map((index, i) => {
                    const percentage = (i == 0 ? strategy.index1Percentage : strategy.index2Percentage) * 100
                    return `${percentage}% - ${index.name}`
                }).join('<br />')
            })
        } else {
            specs.push({ key: 'Index', value: strategy.indices[0].name })
        }
    }

    let index = ''
    if (strategy.par1Low) index += `${formatPercentage(strategy.par1Low)}%`
    if (strategy.par1High) index += ` (low band), ${formatPercentage(strategy.par1High)}% (high band)`
    if (strategy.par2Low) index += `<br />${formatPercentage(strategy.par2Low)}%`
    if (strategy.par2High) index += ` (low band), ${formatPercentage(strategy.par2High)}% (high band)`
    if (index.length) {
        specs.push({ key: 'Participation Rates', value: index })
    }

    if (strategy.cap) {
        specs.push({ key: 'Cap', value: formatPercentage(strategy.cap) + '%' })
    }

    if (strategy.hasOwnProperty('floor')) {
        specs.push({ key: 'Floor', value: formatPercentage(strategy.floor) + '%' })
    }

    technicalStrategySpecifications.value = specs
})

const accordionOpened = (title) => {
    event('sdp_accordion_clicked', {
        section_title: title
    })
}
</script>