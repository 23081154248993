<template>
    <QuestionnaireSection title="What is your risk tolerance?" @click="nextStep"
        :disableNext="!store.validateStep('risk-tolerance')">
        <div class="space-y-4 mt-4 mb-8">
            <button @click="store.setField('riskTolerance', level.value)"
                class="w-full bg-gray-100 border-2 border-l-4 hover:bg-primary-300 rounded px-4 py-3 text-left"
                :style="`border-color: ${store.riskTolerance === level.value ? level.color : 'transparent'}; border-left-color: ${level.color}; background: ${store.riskTolerance === level.value ? level.color + '33' : '#f3f4f6'};`"
                v-for="level in toleranceLevels">
                <p class="text-xl font-serif font-bold text-gray-800">{{ level.title }}
                </p>
                <p class="text-xs text-gray-800">{{
                    level.description }}</p>
            </button>
        </div>
    </QuestionnaireSection>
</template>

<script setup>
import { onMounted } from 'vue';
import QuestionnaireSection from './QuestionnaireSection.vue';
import { useQuestionnaireStore } from '../../stores/questionnaireStore';
import { event } from 'vue-gtag';
import { QUESTIONNAIRE_STEPS } from '../../utils';
import { useRouter } from 'vue-router';

const router = useRouter()
const store = useQuestionnaireStore();

const toleranceLevels = [
    {
        title: 'Secure',
        description: 'I prefer predictability and guarantees over higher returns. I traditionally invest in CDs, money market funds, or savings.',
        value: '1',
        color: '#4d7c0f'
    },
    {
        title: 'Balanced',
        description: 'I am comfortable with small market fluctuations.',
        value: '2',
        color: '#1454f2'
    },
    {
        title: 'Aggressive',
        description: 'I can accept periods of volatility in exchange for a higher long term rate of return.',
        value: '3',
        color: '#ea580c'
    },
]

const nextStep = () => {
    event('risk_tolerance_selected', {
        risk_tolerance: store.riskTolerance
    })
    router.push(store.nextStep())
}

onMounted(() => {
    store.setStep(QUESTIONNAIRE_STEPS.RISK_TOLERANCE)
    event('page_view', {
        page_title: 'Master App Questionnaire - Risk Tolerance',
        page_location: window.location.href,
        page_path: window.location.pathname
    })
})
</script>