<template>
    <div class="fixed bottom-0 left-0 lg:left-72 right-0 bg-white border-t">
        <div class="px-4 py-4 lg:px-6 flex justify-between">
            <div class="w-auto">
                <SecondaryButton block @click="handlePrevButtonClick" v-if="thisInstance?.vnode?.props?.onPrevStep">
                    Previous
                </SecondaryButton>
            </div>
            <div class="w-auto">
                <PrimaryButton block @click="handleNextButtonClick" :disabled="buttonDisabled">
                    {{ buttonText || "Next" }}
                </PrimaryButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getCurrentInstance } from 'vue';
import { useApplicationStore } from '../stores/applicationStore';
import { PrimaryButton, SecondaryButton } from '@reviseannuity/randolph-ui';

const thisInstance = getCurrentInstance();
const store = useApplicationStore()

const emit = defineEmits(['nextStep', 'prevStep'])

defineProps({
    buttonDisabled: {
        type: Boolean,
        default: true
    },
    buttonText: String
})

const handlePrevButtonClick = () => {
    store.saveApplicationProgress()
    emit('prevStep')
}

const handleNextButtonClick = () => {
    store.saveApplicationProgress()
    emit('nextStep')
}
</script>