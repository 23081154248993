<template>
    <div class="relative w-full bg-white shadow-lg rounded-lg p-6 sm:grid-cols-2 gap-x-4 gap-y-2" :class="{
        'border-2 border-primary-600': bestFit,
        'flex flex-col border border-gray-100 h-full': small,
        'grid': !small
    }">
        <div class="absolute top-0 right-0 flex">
            <div class="text-[0.5em] font-semibold text-green-600 flex items-center"
                :class="{ 'px-2.5 py-1.5': bestFit, 'px-3 py-3': !bestFit }" v-if="rolloverApproved">
                <CheckBadgeIcon class="mr-1 w-3 inline-block" />
                401(k) approved
            </div>
            <div class="text-xs font-semibold text-white bg-primary-600 px-2.5 py-1.5 rounded-bl flex items-center gap-1 whitespace-nowrap"
                v-if="bestFit">
                <HandThumbUpIcon class="w-3 inline-block" />
                Best
                Fit
            </div>
        </div>
        <div class="col-span-full">
            <h3 class="text-2xl sm:text-3xl font-serif font-bold col-span-full text-left mb-2">{{ title }}</h3>
            <div :class="{ 'mb-4': reviews, 'mb-2': !reviews }">
                <div class="flex items-center gap-2">
                    <StrategyTypeTag :type="strategyType" />
                    <div class="flex gap-1 text-xs items-center text-gray-600" v-if="reviews">
                        <StarRating :rating="compositeReviewRating" small /> ({{ reviews?.length || 0 }} reviews)
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col justify-between text-left" :class="{ 'mb-8': small }">
            <p class="text-sm text-gray-600 mb-2 sm:mb-8">{{ description }}</p>
            <div class="text-center mb-6 sm:mb-0" :class="{ 'sm:text-left': !small }" v-if="indexes?.length">
                <p class="font-semibold text-xs">Credited from</p>
                <p class="text-xs text-gray-600" v-for="index in indexes" :key="index.id">{{ index.name }}</p>
            </div>
        </div>
        <div class="mt-auto">
            <div class="grid grid-cols-2 grid-rows-2 gap-6 mt-auto" :class="{ 'sm:gap-6': small, 'sm:gap-3': !small }">
                <div class="text-center flex flex-col justify-center items-stretch" v-for="block in dataBlocks">
                    <strong class="block text-[0.65em] uppercase text-gray-500 sm:mb-2">{{ block.title }}</strong>
                    <span class="text-xl leading-tight sm:text-2xl font-serif font-bold" v-html="block.value" />
                </div>
            </div>
            <div class="mt-6 w-3/4 mx-auto mb-6" v-if="['growth', 'myga'].includes(strategyType)">
                <VolatilityChart :value="volatility" :min="minVolatility" :max="maxVolatility" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { HandThumbUpIcon, CheckBadgeIcon } from '@heroicons/vue/20/solid';
import { StarRating } from '@reviseannuity/randolph-ui'
import VolatilityChart from './VolatilityChart.vue';
import { formatPercentage } from '../utils'
import StrategyTypeTag from './StrategyTypeTag.vue';
import { _boundSegment } from 'chart.js/helpers';

const props = defineProps({
    title: String,
    description: String,
    indexes: Array,
    strategyFee: Number,
    surrenderPeriod: Number,
    liquidityProvision: String,
    rate: Number,
    tags: Array,
    bestFit: Boolean,
    rolloverApproved: Boolean,
    rating: String,
    small: {
        type: Boolean,
        default: false
    },
    reviews: Array,
    volatility: Number,
    minVolatility: Number,
    maxVolatility: Number,
    strategyType: String,
    assetGrowthRate: Number,
    productFee: Number,
    incomeGrowthRate: Number,
    bonus: Number
})

const compositeReviewRating = props.reviews?.length ? props.reviews.reduce((acc, review) => acc + review.rating, 0) / props.reviews.length : null
let dataBlocks

if (props.strategyType === 'growth') {
    dataBlocks = [
        { title: "Hist. Rate", value: `${formatPercentage(props.rate)}<sup class="-mr-3">%</span>` },
        { title: "Strategy Fee", value: `${formatPercentage(props.strategyFee)}<sup class="-mr-3">%</span>` },
        { title: "Surrender Period", value: `${props.surrenderPeriod} <small>yrs</small>` },
        { title: "Free Withdrawal", value: `${props.liquidityProvision}<sup class="-mr-3">%</span>` }
    ]
}

if (props.strategyType === 'hybrid') {
    dataBlocks = [
        { title: "Hist. Rate", value: `${formatPercentage(props.assetGrowthRate)}<sup class="-mr-3">%</span>` },
        { title: "Income Growth", value: `${formatPercentage(props.incomeGrowthRate)}<sup class="-mr-3">%</span>` },
        { title: "Surrender Period", value: `${props.surrenderPeriod} <small>yrs</small>` },
        { title: "Product Fees", value: `${formatPercentage(props.productFee)}<sup class="-mr-3">%</span>` }
    ]
}

if (props.strategyType === 'income') {
    dataBlocks = [
        { title: "Hist. Rate", value: `${formatPercentage(props.assetGrowthRate)}<sup class="-mr-3">%</span>` },
        { title: "Income Growth", value: `<small>Age-based</small>` },
        { title: "Surrender Period", value: `${props.surrenderPeriod} <small>yrs</small>` },
        { title: "Product Fees", value: `${formatPercentage(props.productFee)}<sup class="-mr-3">%</span>` }
    ]
}

if (props.strategyType === 'myga') {
    dataBlocks = [
        { title: "Guaranteed Rate", value: `${formatPercentage(props.rate)}<sup class="-mr-3">%</span>` },
        { title: "Guarantee Period", value: `${props.surrenderPeriod} <small>yrs</small>` },
        { title: "Free Withdrawal", value: `${props.liquidityProvision}` },
        { title: "Strategy Fee", value: `${formatPercentage(props.strategyFee)}<sup class="-mr-3">%</span>` },
    ]
}

if (props.strategyType === 'bonus') {
    dataBlocks = [
        { title: "Historical rate", value: `${formatPercentage(props.rate)}<sup class="-mr-3">%</span>` },
        { title: "Bonus", value: `${formatPercentage(props.bonus)}<sup class="-mr-3">%</span>` },
        { title: "Surrender Period", value: `${props.surrenderPeriod} <small>yrs</small>` },
        { title: "Product Fee", value: `${formatPercentage(props.productFee)}<sup class="-mr-3">%</span>` }
    ]
}
</script>