<template>
    <SDPWrapper>
        <h2 class="text-xl sm:text-2xl font-bold font-serif mb-4 text-center">Other popular strategies</h2>
        <div class="relative">
            <div v-if="recommendedScrolledPastStart">
                <!-- <button @click="scrollLeft"
                    class="absolute top-1/2 -mt-12 right-[100%] h-16 w-16 bg-white rounded-full shadow flex justify-center items-center z-20">
                    <ChevronLeftIcon class="h-12 text-primary-600" />
                </button> -->
                <div
                    class="pointer-events-none absolute left-0 top-0 bottom-0 w-32 bg-gradient-to-r from-white via-white via-10% to-transparent z-10">
                </div>
            </div>
            <div v-if="!recommendedScrolledToEnd">
                <!-- <button @click="scrollRight"
                    class="absolute top-1/2 -mt-12 left-[100%] h-16 w-16 bg-white rounded-full shadow flex justify-center items-center z-20">
                    <ChevronRightIcon class="h-12 text-primary-600" />
                </button> -->
                <div
                    class="pointer-events-none absolute right-0 top-0 bottom-0 w-32 bg-gradient-to-l from-white via-white via-10% to-transparent z-10">
                </div>
            </div>
            <div class="overflow-scroll no-scrollbar" ref="recommended" @scroll="scroll" v-dragscroll>
                <div class="flex flex-nowrap gap-4">
                    <div class="w-80 grow-0 shrink-0 mb-8 select-none" v-for="strategy, i in recommendedStrategies">
                        <StrategyCard class="cursor-pointer"
                            @click="router.push(`/get-started/strategy/${strategy.id}?ref=recommended`)" small
                            :title="strategy.name" :description="strategy.shortDescription" :indexes="strategy.indices"
                            :strategyFee="strategy.strategyFee" :surrenderPeriod="strategy.surrenderPeriod"
                            :liquidityProvision="formatPercentage(strategy.underlyingProduct.liquidityProvision)"
                            :rate="strategy.historicalRate" :tags="strategy.tags" :volatility="strategy.volatility"
                            :strategyType="strategy.type" :rolloverApproved="strategy.rolloverApproved"
                            :productFee="strategy.productFee" />
                    </div>
                </div>
            </div>
        </div>
    </SDPWrapper>
</template>

<script setup>
import SDPWrapper from './SDPWrapper.vue';
import StrategyCard from '../StrategyCard.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { formatPercentage } from '../../utils';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';

const router = useRouter()
const { recommendedStrategies } = defineProps({
    recommendedStrategies: Array,
})

const recommended = ref(null)
const recommendedScrolledPastStart = ref(false)
const recommendedScrolledToEnd = ref(false)

const scroll = () => {
    if (!recommended.value) return
    const el = recommended.value
    recommendedScrolledPastStart.value = el.scrollLeft > 0
    recommendedScrolledToEnd.value = el.scrollLeft + el.clientWidth == el.scrollWidth
}

const scrollLeft = () => {
    if (!recommended.value) return
    recommended.value.scrollTo({ left: recommended.value.scrollLeft - recommended.value.clientWidth, behavior: "smooth" })
}

const scrollRight = () => {
    if (!recommended.value) return
    recommended.value.scrollTo({ left: recommended.value.scrollLeft + recommended.value.clientWidth, behavior: "smooth" })
}
</script>