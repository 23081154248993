import { createRouter, createWebHistory } from "vue-router";
import Application from "./pages/ApplicationPage.vue";
import Registration from "./pages/RegistrationPage.vue";
import Questionnaire from "./pages/QuestionnairePage.vue";
import Dashboard from "./pages/DashboardPage.vue";
import Root from "./pages/RootPage.vue";
import { authGuard } from '@auth0/auth0-vue';

import {
    WelcomeSection,
    VerificationSection,
    PersonalInformationSection,
    FundingSourcesSection,
    OwnershipSection,
    AnnuitantSection,
    BeneficiariesSection,
    ExistingPoliciesSection,
    HouseholdInformationSection,
    SuitabilityAttestationSection,
    ReviewSection
} from "./sections";
import {
    ZipCodeSection,
    BirthdateSection,
    RiskToleranceSection,
    StrategyListSection,
    StrategyDetailsSection,
    InvestmentAmountSection as QuestionnaireInvestmentAmountSection,
    FocusSection
} from "./components/questionnaireSections";
import {
    LiquidFundsSection,
    UserInfoSection,
    FundingSourceSection,
    InvestmentAmountSection,
    EmployerAccountAmountSection,
    RolloverBookingSection,
    InitialPremiumAmountSection
} from "./components/registrationSections";

const routes = [
    {
        name: 'root',
        path: '/',
        component: Root,
        beforeEnter: authGuard
    },
    {
        name: 'questionnaire',
        path: '/get-started',
        redirect: '/get-started/zip',
        component: Questionnaire,
        children: [
            {
                path: 'zip',
                component: ZipCodeSection
            },
            {
                path: 'birthdate',
                component: BirthdateSection
            },
            {
                path: 'risk-tolerance',
                component: RiskToleranceSection
            },
            {
                path: 'investment-amount',
                component: QuestionnaireInvestmentAmountSection
            },
            {
                path: 'strategy',
                component: StrategyListSection
            },
            {
                path: 'strategy/:strategyId',
                component: StrategyDetailsSection
            },
            {
                path: 'focus',
                component: FocusSection
            }
        ]
    },
    {
        name: 'register',
        path: '/register',
        redirect: '/register/info',
        component: Registration,
        beforeEnter: authGuard,
        children: [
            {
                name: 'registration_info',
                path: 'info',
                component: UserInfoSection
            },
            {
                name: 'registration_funding',
                path: 'funding',
                component: FundingSourceSection
            },
            {
                name: 'registration_liquid_funds',
                path: 'liquid-funds',
                component: LiquidFundsSection
            },
            {
                name: 'registration_investment_amount',
                path: 'investment-amount',
                component: InvestmentAmountSection
            },
            {
                name: 'registration_qualified_investment_amount',
                path: 'qualified-investment-amount',
                component: EmployerAccountAmountSection
            },
            {
                name: 'registration_rollover_booking',
                path: 'rollover-booking',
                component: RolloverBookingSection
            },
            {
                name: 'registration_initial_premium_amount',
                path: 'initial-premium-amount',
                component: InitialPremiumAmountSection
            }
        ]
    },
    {
        name: 'apply',
        path: '/application',
        component: Application,
        redirect: '/application/welcome',
        beforeEnter: authGuard,
        children: [
            {
                path: 'welcome',
                component: WelcomeSection
            },
            {
                path: 'personal-information',
                component: PersonalInformationSection
            },
            {
                path: 'verification',
                component: VerificationSection
            },
            {
                path: 'funding-sources',
                component: FundingSourcesSection
            },
            {
                path: 'ownership',
                component: OwnershipSection
            },
            {
                path: 'annuitant',
                component: AnnuitantSection
            },
            {
                path: 'beneficiaries',
                component: BeneficiariesSection
            },
            {
                path: 'existing-policies',
                component: ExistingPoliciesSection
            },
            {
                path: 'household-information',
                component: HouseholdInformationSection
            },
            {
                path: 'suitability-attestation',
                component: SuitabilityAttestationSection
            },
            {
                path: 'review',
                component: ReviewSection
            },
            {
                path: '*',
                component: WelcomeSection
            }
        ]
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        component: Dashboard,
        beforeEnter: authGuard,
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                top: to?.params?.offset,
                behavior: 'smooth'
            }
        }
        return { top: 0 }
    },
})

router.beforeEach(() => {
    if (window.reviseTracking) {
        window.reviseTracking.event('pageView')
    }
})

export { router }