<template>
    <div class="lg:absolute pl-0 lg:pt-0 lg:pl-72 inset-0">
        <div class="gap-12 grid lg:place-items-center h-full overflow-x-hidden lg:px-12">
            <div class="opacity-0 transition-all duration-300 transform col-span-full row-span-full w-full max-w-4xl flex-col items-center lg:flex-row  flex gap-12"
                :class="{ 'pointer-events-none': activeSubStep !== 0, 'pointer-events-all opacity-100 translate-x-0': activeSubStep === 0, '-translate-x-32': activeSubStep === 1 }">
                <img :src="contractIllustration" class="max-w-48 lg:max-w-xs" />
                <div class="text-left">
                    <h2 class="text-2xl lg:text-3xl mb-4 lg:mb-6 font-serif font-bold">Hey{{
                        store.personalInformation.name ? ` ${store.personalInformation.name.split(' ')[0]},` : `,` }}
                        get
                        ready to
                        grow
                        your wealth.
                    </h2>
                    <p class="mb-4 lg:mb-6 text-gray-800">This application will take approximately 20 minutes to
                        complete. Your progress is saved automatically. This information is
                        end-to-end encrypted, it will only be shared directly with the carrier. This will not affect
                        your
                        credit score.</p>
                    <PrimaryButton @click="() => activeSubStep = 1" class="w-full lg:w-auto">Continue</PrimaryButton>
                </div>
            </div>


            <div class="opacity-0 transition-all duration-300 transform  col-span-full row-span-full w-full max-w-3xl flex flex-col-reverse lg:flex-row justify-end lg:justify-center items-center gap-8 lg:gap-12"
                :class="{ 'pointer-events-none': activeSubStep !== 1, 'pointer-events-none translate-x-32': activeSubStep === 0, 'pointer-events-all opacity-100 translate-x-0': activeSubStep === 1, '-translate-x-32': activeSubStep === 2 }">
                <div>
                    <h2 class="text-lg lg:text-xl font-serif font-bold mb-2">Were
                        you referred to us by
                        someone?</h2>
                    <p class="mb-4 text-gray-800">
                        Our business thrives on word of mouth. Let us know who referred you - your friend will receive
                        credit for making the introduction.
                    </p>
                    <RadioField
                        :options="[{ label: 'Yes', disabled: false, value: 'yes' }, { label: 'No', disabled: false, value: 'no' }]"
                        name="wereYouReferred" :value="store.wereYouReferred"
                        @input="v => store.setField('wereYouReferred', v)" />
                    <AutocompleteField class="mt-4" label="Full name of your referral" :options="partners"
                        v-if="store.wereYouReferred === 'yes'"
                        @select="v => store.setField('referringPartner', { type: 'partner', value: v })"
                        @input="v => store.setField('referringPartner', { type: 'other', value: v })" />
                    <div class="flex justify-end mt-8">
                        <PrimaryButton
                            @click="() => store.wereYouReferred == 'no' ? activeSubStep = 2 : handleContinue()"
                            :disabled="store.wereYouReferred === null || store.wereYouReferred === 'yes' && !store?.referringPartner?.value">
                            Continue
                        </PrimaryButton>
                    </div>
                </div>
                <img :src="handshakeIllustration" class="max-w-40 lg:max-w-60" />

            </div>


            <div class="opacity-0 transition-all duration-300 transform col-span-full row-span-full w-full max-w-3xl flex flex-col justify-start lg:justify-center lg:flex-row items-center gap-8 lg:gap-12"
                :class="{ 'pointer-events-none': activeSubStep !== 2, 'translate-x-32': activeSubStep === 1, 'pointer-events-all opacity-100 translate-x-0': activeSubStep === 2 }">
                <img :src="newsletterIllustration" class="max-w-48 lg:max-w-60" />
                <div>
                    <h2 class="text-lg lg:text-xl font-serif font-bold mb-2">How did you hear about Revise?</h2>
                    <p class=" mb-4 text-gray-800">
                        We're curious to know how you found us. This helps us serve you and customers like you better in
                        the
                        future.
                    </p>
                    <SelectField :options="shuffleArray(referralOptions)" :value="store.referrer"
                        @input="v => store.setField('referrer', v)" placeholder="Select option..." />
                    <div class="flex justify-between mt-8">
                        <SecondaryButton @click="activeSubStep--">Back</SecondaryButton>
                        <PrimaryButton @click="handleContinue"
                            :disabled="store.wereYouReferred === 'no' && !store.referrer">
                            Continue
                        </PrimaryButton>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script setup>
import contractIllustration from '../assets/signing_contract.svg';
import handshakeIllustration from '../assets/handshake.svg';
import newsletterIllustration from '../assets/newsletter.svg';
import { onMounted, ref } from 'vue';
import { useApplicationStore } from '../stores/applicationStore';
import { useStep } from '../composables/useStep';
import { PrimaryButton, SecondaryButton, RadioField, SelectField, AutocompleteField } from '@reviseannuity/randolph-ui';
import { event } from 'vue-gtag';
import { shuffleArray } from '../utils';
import { updateUser } from '../clients/user';
import { getAllPartners } from '../clients/partner';

const store = useApplicationStore()
const { nextStep } = useStep(store)
const activeSubStep = ref(0)
const partners = ref([]);

const handleContinue = () => {
    event('welcome_complete')
    updateUser(store.user.auth0_id, {
        referring_partner: store.referringPartner.value,
        self_attribution: store.referringPartner.value ? 'partner' : store.referrer,
    })
    nextStep()
}

onMounted(async () => {
    await store.saveApplicationProgress()
    const data = await getAllPartners()
    partners.value = data.map(partner => ({
        label: partner.name,
        value: partner.publicId
    }))
})

const referralOptions = [
    { label: 'Facebook', value: 'facebook' },
    { label: 'Google', value: 'google' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'LinkedIn', value: 'linkedin' },
    { label: 'Outdoor (Billboard, Sticker, etc.)', value: 'outdoor' },
    { label: 'TikTok', value: 'tiktok' },
    { label: 'YouTube', value: 'youtube' },
    { label: 'Podcast', value: 'podcast' },
]
</script>