<template>
    <QuestionnaireSection title="Welcome to Revise." helperText="Let's continue with some basic information."
        :disableNext="!store.firstName.length || !store.lastName.length || !emailIsValid(store.email) || !store.optIn"
        @click="submitUserInformation" :loading="store.submitting" :enableEnterKey="false">
        <div class="grid grid-cols-1 gap-4 mb-8">
            <TextField :value="store.firstName" @input="e => store.setField('firstName', e)" label="First Name" />
            <TextField :value="store.lastName" @input="e => store.setField('lastName', e)" label="Last Name" />
            <div>
                <TextField :value="store.email" @input="e => store.setField('email', e)" label="Email" />

                <ErrorMessage v-if="store.email.length && !emailIsValid(store.email)">
                    Looks like that isn't a valid email.
                </ErrorMessage>
            </div>
            <CheckboxField :value="store.optIn" @input="e => store.setField('optIn', e)"
                label="<p class='text-xs leading-0'>I consent to receiving communications from Revise Annuity in accordance with our <a href='https://getrevise.com/privacy' class='underline' target='_blank'>privacy policy</a>.</p>"
                class="text-sm text-gray-600" />
        </div>
    </QuestionnaireSection>
</template>

<script setup>
import { ref } from 'vue';
import QuestionnaireSection from '../questionnaireSections/QuestionnaireSection.vue';
import { TextField, CheckboxField, ErrorMessage } from '@reviseannuity/randolph-ui';
import { emailIsValid } from '../../utils';
import { createUser } from '../../clients/user';
import { signUpEvents } from '../../eventTagging';
import { useToast } from 'vue-toast-notification';
import { useRegistrationStore, REGISTRATION_STEPS } from '../../stores/registrationStore';
import { useRouter } from 'vue-router';

const emit = defineEmits(['next']);
const toast = useToast()
const router = useRouter();

const store = useRegistrationStore();

const submitUserInformation = async () => {
    store.setField('submitting', true);

    try {
        console.log(store.age)
        await createUser({
            auth0_id: store.auth0Id,
            first_name: store.firstName,
            last_name: store.lastName,
            email: store.email,
            phone: store.phone,
            strategy_name: store.strategyName,
            funding_amount: Number(store.investmentAmount),
            state: store.state,
            birthdate: store.birthdate,
            age: Number(store.age),
            zip: store.zip
        });

        signUpEvents(
            store.email,
            store.firstName,
            store.lastName,
            store.phone,
            store.strategyName,
            Number(store.investmentAmount)
        )

        // updateQuestionnaireResults()
        store.setActiveStep(REGISTRATION_STEPS.FUNDING);
    } catch (error) {
        toast.error(`An error occurred while creating your account. ${error || 'Please try again.'}`);
    } finally {
        store.setField('submitting', false);
    }
}
</script>
