import { event } from "vue-gtag"
import { callZapierWebhook, ZAPIER_WEBHOOKS } from "./clients/zapier";
import { isProduction } from "./utils";

const GOOGLE_ADS_CONVERSION_ID = 'AW-779555572'
const GOOGLE_ANALYTICS_MEASUREMENT_ID = 'G-LB5BT7V27L'

/**
 * 
 * Events fired when the questionnaire is started
 * 
 */
export const questionnaireStartedEvents = () => {
    if (!isProduction) return

    // Google Analytics and Ads
    event('questionnaire_started', {
        send_to: GOOGLE_ANALYTICS_MEASUREMENT_ID
    })

    // Facebook Pixel
    if (window.fbq) {
        fbq('trackCustom', 'questionnaire_started')
    }

    // TikTok
    if (window.ttq) {
        ttq.track('QuestionnaireStarted')
    }

    // LinkedIn
    if (window.lintrk) {
        window.lintrk('track', { conversion_id: 17411836 });
    }
}

/**
 * 
 * Events fired when the questionnaire is completed
 * 
 * @param {number} investmentAmount
 */
export const questionnaireCompletedEvents = (investmentAmount) => {
    if (!isProduction) return

    // Google Ads
    event('conversion', {
        send_to: `${GOOGLE_ADS_CONVERSION_ID}/-XZgCM78gOgZEPSl3PMC`,
        value: Number(investmentAmount) * 0.085,
        currency: 'USD'
    })
}

/**
 * 
 * Events fired when a user signs up
 * 
 * @param {string} email
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} phone
 * @param {string} strategyName
 * @param {number} investmentAmount
 */
export const signUpEvents = (
    email,
    firstName,
    lastName,
    phone,
    strategyName,
    investmentAmount
) => {
    if (!isProduction) return

    // Google Analytics
    event('sign_up', {
        send_to: GOOGLE_ANALYTICS_MEASUREMENT_ID,
        method: 'email',
        email: email,
    })

    // Google Ads
    event('conversion', {
        send_to: `${GOOGLE_ADS_CONVERSION_ID}/KKDlCODg_ucZEPSl3PMC`,
        value: Number(investmentAmount) * 0.085,
        currency: 'USD'
    })

    // Facebook Pixel
    if (window.fbq) {
        fbq('track', 'CompleteRegistration')
    }


    // Zapier
    callZapierWebhook(ZAPIER_WEBHOOKS.SIGN_UP, {
        email,
        firstName,
        lastName,
        phone,
        strategyName,
        investmentAmount
    })

    // TikTok
    if (window.ttq) {
        ttq.track('CompleteRegistration')
    }

    // LinkedIn
    if (window.lintrk) {
        window.lintrk('track', { conversion_id: 17411828 });
    }
}

/**
 * 
 * Events fired when an applicaiton is started
 * 
 * @param {number} investmentAmount
 * @param {string} state
 * @param {string} name
 * @param {string} email
 * @param {string} phone
 * @param {string} birthdate
 */
export const applicationStartedEvents = (
    investmentAmount,
    state,
    name,
    email,
    phone,
    birthdate
) => {
    if (!isProduction) return

    // Google Analytics and Ads
    event('application_started', {
        send_to: GOOGLE_ANALYTICS_MEASUREMENT_ID
    })

    // Facebook Pixel
    if (window.fbq) {
        fbq('trackCustom', 'application_started')
    }

    // Zapier
    callZapierWebhook(ZAPIER_WEBHOOKS.APPLICATION_STARTED, {
        investmentAmount,
        state,
        name,
        email,
        phone,
        birthdate
    })

    // TikTok
    if (window.ttq) {
        ttq.track('ApplicationStarted')
    }

    // LinkedIn
    if (window.lintrk) {
        window.lintrk('track', { conversion_id: 17411820 });
    }
}

/**
 * 
 * Events fired when an application is submitted
 * 
 * @param {number} investmentAmount
 * @param {string} applicationId
 * @param {object} state
 */
export const applicationSubmittedEvents = (investmentAmount, applicationId, state) => {
    if (!isProduction) return

    // Google Analytics and Ads
    event('conversion', {
        send_to: [
            GOOGLE_ANALYTICS_MEASUREMENT_ID,
            `${GOOGLE_ADS_CONVERSION_ID}/8NhWCK2cpJIZEPSl3PMC`
        ],
        value: Number(investmentAmount) * 0.085,
        currency: 'USD',
        transaction_id: applicationId
    })

    // Facebook Pixel
    if (window.fbq) {
        fbq('track', 'Purchase', {
            value: Number(investmentAmount) * 0.085,
            currency: 'USD',
        })
    }

    // Zapier
    callZapierWebhook(ZAPIER_WEBHOOKS.APPLICATION_SUBMITTED, state)

    // TikTok
    if (window.ttq) {
        ttq.track('CompletePayment', {
            value: Number(investmentAmount) * 0.085,
            currency: 'USD',
        })
    }

    // LinkedIn
    if (window.lintrk) {
        window.lintrk('track', {
            conversion_id: 17411812,
            value: Number(investmentAmount) * 0.085,
        })
    };

    if (window.trackdesk) {
        trackdesk("revise", "click")
        trackdesk("revise", "conversion", {
            "conversionType": "sale"
        });
    }

    window.VWO = window.VWO || [];
    VWO.event = VWO.event || function () { VWO.push(['event'].concat([].slice.call(arguments))) };
    VWO.event('applicationSubmitted', {
        'investmentAmount': Number(investmentAmount)
    })
}
/**
 * 
 * Events fired when a user schedules a meeting
 * 
 * @param {string} date
 * @param {string} auth0_id
 * @param {number} investmentAmount
 * @param {string} email
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} phone
 * @param {string} strategyName
 */
export const meetingBookedEvents = (date, auth0_id, investmentAmount, email, firstName, lastName, phone, strategyName) => {
    if (!isProduction) return

    event('meeting_scheduled', {
        date: date
    })

    if (window.fbq) {
        fbq('track', 'Purchase', {
            value: Number(store?.investmentAmount) * 0.085,
            currency: 'USD',
        })
    }

    callZapierWebhook(ZAPIER_WEBHOOKS.ROLLOVER_BOOKED, {
        email: email,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        strategy_name: strategyName,
        funding_amount: Number(investmentAmount),
        rollover_meeting: date
    })
}

export const loadMetaPixel = () => {
    !function (f, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
    }(window, document, 'script',
        'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '267489385853803');
    fbq('track', 'PageView');
}

export const loadTikTokPixel = () => {
    !function (w, d, t) {
        w.TiktokAnalyticsObject = t; var ttq = w[t] = w[t] || []; ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie", "holdConsent", "revokeConsent", "grantConsent"], ttq.setAndDefer = function (t, e) { t[e] = function () { t.push([e].concat(Array.prototype.slice.call(arguments, 0))) } }; for (var i = 0; i < ttq.methods.length; i++)ttq.setAndDefer(ttq, ttq.methods[i]); ttq.instance = function (t) {
            for (
                var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)ttq.setAndDefer(e, ttq.methods[n]); return e
        }, ttq.load = function (e, n) {
            var r = "https://analytics.tiktok.com/i18n/pixel/events.js", o = n && n.partner; ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = r, ttq._t = ttq._t || {}, ttq._t[e] = +new Date, ttq._o = ttq._o || {}, ttq._o[e] = n || {}; n = document.createElement("script")
                ; n.type = "text/javascript", n.async = !0, n.src = r + "?sdkid=" + e + "&lib=" + t; e = document.getElementsByTagName("script")[0]; e.parentNode.insertBefore(n, e)
        };

        ttq.load('CR8CMKRC77UFBBI22I2G');
        ttq.page();
    }(window, document, 'ttq');
}

export const loadLinkedInPixel = () => {
    const _linkedin_partner_id = "6488476";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);

    (function (l) {
        if (!l) {
            window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
            window.lintrk.q = []
        }
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript"; b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
    })(window.lintrk);
}

export const loadRevisePixel = () => {
    var v = "1726517836"
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript"; b.defer = true;
    b.src = `https://revise-tracking-pixel.s3.amazonaws.com/main-${v}.js`;
    s.parentNode.insertBefore(b, s);
}

export const loadTrackDeskPixel = () => {
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript"; b.async = true;
    b.src = `//cdn.trackdesk.com/tracking.js`;
    s.parentNode.insertBefore(b, s);
    (function (t, d, k) { (t[k] = t[k] || []).push(d); t[d] = t[d] || t[k].f || function () { (t[d].q = t[d].q || []).push(arguments) } })(window, "trackdesk", "TrackdeskObject");
}