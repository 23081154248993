<template>
    <div class="flex justify-center items-center flex-col pt-10 pb-16">
        <SpinningLoader color="#1454f2" class="w-24" />
        <div class="relative w-full mt-8">
            <div v-for="phrase, i in loadingPhrases"
                class="absolute inset-0 text-center text-2xl text-gray-600 transition-all duration-500 font-serif font-bold"
                :class="{ 'translate-y-2 opacity-0': i != activePhrase, 'translate-y-0 opacity-100': i == activePhrase }">
                {{
                    phrase }}</div>
        </div>
    </div>
</template>

<script setup>
import { SpinningLoader } from '@reviseannuity/randolph-ui';
import { onUnmounted, ref } from 'vue';
import { loadingPhrases } from '../data/strategyList';

const activePhrase = ref(0)

let interval = setInterval(() => {
    activePhrase.value = (activePhrase.value + 1) % loadingPhrases.length
}, 1500)

onUnmounted(() => {
    clearInterval(interval);
});
</script>