<template>
    <SectionContainer title="All done! Let's review." buttonText="Submit application"
        :button-disabled="!store.readyForSubmission" @prevStep="router.push(store.steps[store.steps.length - 1].url)"
        @nextStep="submitApplication" :saveProgress="false">
        <HelperText>Let's make sure everything looks right before we submit to a licensed agent for review.
        </HelperText>
        <div class="group">
            <button class="headerButton">
                <h2>Allocation Summary</h2>
                <PencilIcon class="icon" />
            </button>
            <div class="mt-4 mb-8">
                <DetailsTable :rows="investmentDetails" />
            </div>
        </div>
        <div class="group">
            <button @click="goToStep('personal-information')" class="headerButton">
                <h2>Personal Information</h2>
                <PencilIcon class="icon" />
            </button>
            <div class="mt-4 mb-8">
                <DetailsTable :rows="personalInformation" />
            </div>

        </div>
        <div class="group">
            <button @click="goToStep('funding-sources')" class="headerButton">
                <h2>Funding Sources</h2>
                <PencilIcon class="icon" />
            </button>
            <div class="mt-4 mb-8">
                <DetailsTable :rows="fundingSources" />
            </div>
        </div>
        <div class="group">
            <button @click="goToStep('ownership')" class="headerButton">
                <h2>Ownership</h2>
                <PencilIcon class="icon" />
            </button>
            <div class="mt-4 mb-8">
                <DetailsTable :rows="ownership" />
            </div>
        </div>
        <div class="group">
            <button @click="goToStep('annuitant')" class="headerButton">
                <h2>Annuitant</h2>
                <PencilIcon class="icon" />
            </button>
            <div class="mt-4 mb-8">
                <DetailsTable :rows="annuitant" />
            </div>
        </div>
        <div class="group">
            <button @click="goToStep('beneficiaries')" class="headerButton">
                <h2>Beneficiaries</h2>
                <PencilIcon class="icon" />
            </button>
            <div class="mt-4 mb-8">
                <DetailsTable :rows="beneficiaries" />
            </div>
        </div>
        <div class="group">
            <button @click="goToStep('existing-policies')" class="headerButton">
                <h2>Existing Policies</h2>
                <PencilIcon class="icon" />
            </button>
            <div class="mt-4 mb-8">
                <DetailsTable :rows="existingPolicies" />
            </div>

        </div>
        <div class="group">
            <button @click="goToStep('household-information')" class="headerButton">
                <h2>Household Information</h2>
                <PencilIcon class="icon" />
            </button>
            <div class="mt-4 mb-8">
                <DetailsTable :rows="householdInformation" />
            </div>
        </div>
    </SectionContainer>
</template>

<script setup>
import { PencilIcon } from '@heroicons/vue/24/solid';
import SectionContainer from '../components/SectionContainer.vue';
import { useApplicationStore } from '../stores/applicationStore';
import { useRouter } from 'vue-router';
import { DetailsTable, HelperText } from '@reviseannuity/randolph-ui';
import { formatMoney, formatSSN, ownerTypes, annuitantTypes, investmentTypes, formatPercentage } from '../utils';

const router = useRouter()
const store = useApplicationStore()

const goToStep = (step) => {
    router.push(`/application/${step}`)
}

const investmentDetails = [
    { key: 'Strategy Name', value: store.strategy.name },
    { key: 'Initial Investment', value: formatMoney(store.investmentAmount) },
    { key: 'Carrier', value: store.strategy.underlyingProduct.insurer.name },
    { key: 'Carrier Rating', value: store.strategy.underlyingProduct.insurer.rating },
    { key: 'Product Name', value: store.strategy.underlyingProduct.name },
    { key: 'Premium Type', value: `${store.strategy.underlyingProduct.premiumFrequency} ${store.strategy.underlyingProduct.payoutType}` },
    { key: 'Surrender Period', value: store.strategy.surrenderPeriod + ' years' },
    { key: 'Crediting', value: store.strategy.crediting },
    { key: 'Product Fee', value: formatPercentage(store.strategy.productFee) + '%' },
    { key: 'Strategy Fee', value: formatPercentage(store.strategy.strategyFee) + '%' },
    { key: 'Index Allocation', value: store.strategy.indices.map(index => `${formatPercentage(index.percentage)}% - ${index.name}`).join('<br />') },
]

const personalInformation = [
    { key: 'Full Name', value: store.personalInformation.name },
    { key: 'Email', value: store.personalInformation.email },
    { key: 'Phone', value: store.personalInformation.phone },
    { key: 'Address', value: `${store.personalInformation.address}<br>${store.personalInformation.city}, ${store.personalInformation.state} ${store.personalInformation.zip}` },
    { key: 'Date of Birth', value: new Date(store.personalInformation.birthdate).toLocaleDateString() },
    { key: 'Social Security Number', value: formatSSN(store.personalInformation.ssn) }
]

const letterOfEngagement = [
    { key: 'Signed?', value: `${store.engagementLetterAgreed ? 'Yes' : 'No'}` }// (<a href="/letter-of-engagement.pdf" download class="text-primary-600 underline">Download PDF</a>)` },
]

const fundingSources = store.fundingSources.map((source, index) => {
    return {
        key: `Funding Source #${index + 1}`,
        value: `${formatMoney(source.amount)}
        <br>${source.institution} ${source.fundsLocation}
        <br><strong>Routing Number: </strong>${source.routingNumber}
        <br><strong>Account Number: </strong>${source.accountNumber}`
    }
})

const ownership = [
    { key: 'Who is the owner?', value: ownerTypes[store.ownerType] },
]
if (store.ownerType == 'joint') {
    ownership.push({ key: 'Joint Owner', value: `${store.jointOwner.firstName} ${store.jointOwner.lastName}<br><strong>SSN:</strong> ${formatSSN(store.jointOwner.ssn)}` })

}

const annuitant = [
    { key: 'Who is the annuitant?', value: annuitantTypes[store.annuitantType] }
]
if (store.annuitantType == 'other') {
    annuitant.push({ key: 'Other Annuitant', value: `${store.otherAnnuitant.firstName} ${store.otherAnnuitant.lastName}<br><strong>SSN:</strong> ${formatSSN(store.otherAnnuitant.ssn)}` })
}

const beneficiaries = store.beneficiaries.map((beneficiary, index) => {
    return {
        key: `Beneficiary #${index + 1}`,
        value: `${beneficiary.firstName} ${beneficiary.lastName}<br><strong>SSN:</strong> ${formatSSN(beneficiary.ssn)}`
    }
})

const existingPolicies = [
    { key: 'Do you have existing policies?', value: store.existingPolicy ? 'Yes' : 'No' }
]
if (store.existingPolicy) {
    existingPolicies.push({
        key: 'Is this policy replacing an existing policy?', value: store.replacingPolicy ? 'Yes' : 'No'
    })
}
if (store.replacingPolicy) {
    existingPolicies.push({
        key: 'Existing Policy', value: `${store.replacingPolicyData.company} - ${store.replacingPolicyData.contractNumber}`
    })

}

const householdInformation = [
    { key: 'Monthly Household Income', value: formatMoney(store.householdIncome) },
    { key: 'Monthly Household Expenses', value: formatMoney(store.householdExpenses) },
]
if (store.householdInvestments.length > 0) {
    const investments = store.householdInvestments.map(investment => `${investmentTypes[investment]} - ${formatMoney(store.householdInvestmentDetails[investment].value)}`)
    householdInformation.push({ key: 'Household Investments', value: investments.join('<br>') })
}

const submitApplication = async () => {
    await store.submitApplication()
    router.push({ name: 'dashboard' })
}
</script>

<style scoped>
.headerButton {
    @apply w-full flex justify-between items-center mt-4 mb-2 py-1 border-t border-b border-gray-400
}

.headerButton h2 {
    @apply text-center w-full text-gray-800 font-semibold text-sm;
}

.headerButton .icon {
    @apply w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100;
}
</style>