<template>
    <SDPWrapper extrathin>
        <div class="grid grid-cols-2 gap-4 sm:gap-0 sm:grid-cols-4 sm:-ml-8 sm:-mr-8 ">
            <div class="text-center flex flex-col justify-center items-stretch sm:border-r" v-for="block of dataBlocks">
                <strong class="block text-[0.65em] uppercase text-gray-500 mb-2">{{ block.title }}
                    <Tooltip :text="block.tooltip" v-if="block.tooltip" />
                </strong>
                <span class="text-2xl font-serif font-bold" v-html="block.value" />
            </div>
        </div>
    </SDPWrapper>
</template>

<script setup>
import { formatPercentage } from '../../utils';
import { Tooltip } from '@reviseannuity/randolph-ui';
import SDPWrapper from './SDPWrapper.vue';

const props = defineProps({
    strategy: Object,
})

let dataBlocks

if (props.strategy.type === 'growth') {
    dataBlocks = [
        {
            title: "Hist. Rate",
            tooltip: "Estimated historical annual effective rate net of fees credited from index or blended strategy. The results obtained from back-testing. As future market returns may vary, this rate should not be considered indicative of the actual results that might be obtained from an investment in the index or strategy. The actual performance of the Index may vary significantly from the results obtained from back-testing.",
            value: `${formatPercentage(props.strategy.historicalRate)}<sup class="-mr-3">%</span>`
        },
        {
            title: "Strategy Fee",
            tooltip: "A strategy fee is an annual percentage fee deducted from your accumulation value in exchange for higher participation in the underlying index.",
            value: `${formatPercentage(props.strategy.strategyFee)}<sup class="-mr-3">%</span>`
        },
        {
            title: "Surrender Period",
            tooltip: "The surrender period is the length of time you are required to hold your annuity before you can withdraw funds without incurring surrender charges. During this period, if you withdraw more than the penalty-free amount (usually up to 10% of the accumulation value annually), you may be subject to surrender charges, which are fees assessed on the amount withdrawn. These charges typically decrease over time and reach 0% after a certain number of years.",
            value: `${props.strategy.surrenderPeriod} <small>yrs</small>`
        },
        {
            tooltip: "Once per year, you may take a penalty-free withdrawal (also known as a penalty-free partial surrender), without surrender charges, of up to 10% of your accumulation value.",
            title: "Free Withdrawal",
            value: `${formatPercentage(props.strategy.underlyingProduct.liquidityProvision)}<sup class="-mr-3">%</span>`
        }
    ]
}

const tooltips = {
    incomeGrowth: "This is the rate of return associated with the capital pool or “benefit base” you draw from if you elect to take income from your annuity. It is often a multiple of the rate of return associated with the market-linked return of your annuity, which provides a larger base of capital from which to draw retirement income. With better market performance, you can expect more income upon income election.",
    productFees: "These are the fees assessed by the insurance carrier for providing capital protection and guaranteed retirement income for various income-based annuity products. They are assessed by the carrier and are not paid to Revise.",
    surrenderPeriod: "The surrender period does not apply to electing a guaranteed income benefit from your annuity which may be elected at any time subject to the terms of your annuity, but rather refers to discretionary cash withdrawals. The surrender period is the length of time you are required to hold your annuity before you can withdraw funds from the accumulation value without incurring surrender charges. The surrender period gives the insurer time to invest your money and provides an opportunity for a competitive return and capital protection. During this period, if you withdraw more than the penalty-free amount (usually up to 10% of the accumulation value annually), you may be subject to surrender charges, which are fees assessed on the amount withdrawn. All fees are listed in the accordion menu below."
}

if (props.strategy.type === 'hybrid') {
    dataBlocks = [
        {
            tooltip: "",
            title: "Hist. Rate",
            value: `${formatPercentage(props.strategy.assetGrowthRate)}<sup class="-mr-3">%</span>`
        },
        {
            tooltip: tooltips.incomeGrowth,
            title: "Income Growth",
            value: `${formatPercentage(props.strategy.incomeGrowthRate)}<sup class="-mr-3">%</span>`
        },
        {
            tooltip: tooltips.surrenderPeriod,
            title: "Surrender Period",
            value: `${props.strategy.surrenderPeriod} <small>yrs</small>`
        },
        {
            tooltip: tooltips.productFees,
            title: "Product Fees",
            value: `${formatPercentage(props.strategy.productFee)}<sup class="-mr-3">%</span>`
        }
    ]
}

if (props.strategy.type === 'income') {
    dataBlocks = [
        {
            tooltip: "",
            title: "Hist. Rate",
            value: `${formatPercentage(props.strategy.assetGrowthRate)}<sup class="-mr-3">%</span>`
        },
        {
            tooltip: tooltips.incomeGrowth,
            title: "Income Growth",
            value: `<small>Age-based</small>`
        },
        {
            tooltip: tooltips.surrenderPeriod,
            title: "Surrender Period",
            value: `${props.strategy.surrenderPeriod} <small>yrs</small>`
        },
        {
            tooltip: tooltips.productFees,
            title: "Product Fees",
            value: `${formatPercentage(props.strategy.productFee)}<sup class="-mr-3">%</span>`
        }
    ]
}

if (props.strategy.type === 'myga') {
    dataBlocks = [
        { title: "Guaranteed Rate", value: `${formatPercentage(props.strategy.historicalRate)}<sup class="-mr-3">%</span>` },
        { title: "Guarantee Period", value: `${props.strategy.surrenderPeriod} <small>yrs</small>` },
        { title: "Free Withdrawal", value: `${formatPercentage(props.strategy.underlyingProduct.liquidityProvision)}<sup class="-mr-3">%</span>` },
        { title: "Strategy Fee", value: `${formatPercentage(props.strategyFee)}<sup class="-mr-3">%</span>` },
    ]
}

if (props.strategy.type === 'bonus') {
    dataBlocks = [
        { title: "Historical rate", value: `${formatPercentage(props.strategy.historicalRate)}<sup class="-mr-3">%</span>` },
        { title: "Bonus", value: `${formatPercentage(props.strategy.bonus)}<sup class="-mr-3">%</span>` },
        { title: "Surrender Period", value: `${props.strategy.surrenderPeriod} <small>yrs</small>` },
        { title: "Product Fee", value: `${formatPercentage(props.strategy.productFee)}<sup class="-mr-3">%</span>` }
    ]
}
</script>