<template>
    <SDPWrapper thin
        class="lg:sticky top-20 bg-white z-50 flex flex-col gap-4 sm:gap-0 sm:flex-row justify-between items-center text-center sm:text-left">
        <div>
            <h2 class="text-2xl sm:text-3xl font-bold font-serif mb-2">
                {{ strategy.name }}
            </h2>
            <p class="text-gray-500 text-sm mb-4">
                {{ strategy.tagline }}
            </p>
            <div class=" flex gap-2 items-center justify-center sm:justify-start mt-2">
                <StrategyTypeTag :type="strategy.type" />
                <div class="flex gap-2 items-center justify-center sm:justify-start" v-if="totalReviews">
                    <StarRating :rating="rating" />
                    <RouterLink :to="{ hash: '#reviews', params: { offset: 200 } }"
                        class="text-gray-400 text-xs underline">
                        ({{ totalReviews }} {{ totalReviews > 1 ? 'reviews' : 'review' }})</RouterLink>
                </div>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="text-center sm:text-right">
                <div class="mb-2 font-semibold text-gray-700">
                    {{ formatMoney(strategy.minimumInvestment) }} - ${{ formatCompactNumber(strategy.maximumInvestment)
                    }}
                </div>
            </div>
            <div
                class="fixed bottom-0 left-0 right-0 z-50 bg-white px-8 pb-8 pt-4 lg:p-0 lg:static flex flex-col border-t border-gray-200">
                <PrimaryButton class="mb-2" @click="continueWithStrategy" id="continueButton">Apply Now
                </PrimaryButton>
                <button class="text-primary-600 underline text-xs whitespace-nowrap"
                    @click="showContactModal = true">Still have more
                    questions?</button>
            </div>
        </div>
    </SDPWrapper>
    <ContactModal :open="showContactModal" @close="showContactModal = false" :birthdate="store?.birthdate"
        :strategyName="strategy?.name" :investmentAmount="store?.investmentAmount" :state="store?.state" name=""
        email="" phone="" calLink="revise/intro" />
</template>

<script setup>
import SDPWrapper from './SDPWrapper.vue';
import { StarRating, PrimaryButton } from '@reviseannuity/randolph-ui';
import { ref } from 'vue';
import { RouterLink, useRouter } from 'vue-router';
import { useQuestionnaireStore } from '../../stores/questionnaireStore';
import ContactModal from '../ContactModal.vue';
import { event } from 'vue-gtag';
import { formatMoney, formatCompactNumber } from '../../utils';
import StrategyTypeTag from '../StrategyTypeTag.vue';

const { strategy } = defineProps({
    strategy: Object,
    totalReviews: Number,
    rating: Number,
})

const store = useQuestionnaireStore();
const router = useRouter()

const showContactModal = ref(false)

const continueWithStrategy = () => {
    event('apply_clicked', {
        strategy: strategy.name
    })
    localStorage.setItem("reviseQuestionnaireResults", JSON.stringify({
        strategy: strategy.id,
        strategyName: strategy.name,
        birthdate: store.birthdate,
        age: store.age,
        investmentAmount: store.investmentAmount,
        state: store.state,
        zip: store.zipCode
    }));
    router.push('/application')
}
</script>