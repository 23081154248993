export const ZAPIER_WEBHOOKS = {
    APPLICATION_STARTED: '2tum60m',
    APPLICATION_SUBMITTED: '2ubmoyx',
    SIGN_UP: '242wezt',
    ROLLOVER_BOOKED: '2tumgh1'
}

export const callZapierWebhook = async (webhook, data) => {
    fetch(`https://hooks.zapier.com/hooks/catch/7043155/${webhook}/`, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
}