<template>
    <SDPWrapper thin>
        <div class="flex gap-2 flex-wrap">
            <div class="text-xs font-semibold text-white bg-primary-600 px-2.5 py-0.5 rounded-full flex items-center gap-1"
                v-if="store.strategies.length && strategy.id == store.strategies[0].id">
                <HandThumbUpIcon class="w-3 inline-block" />
                Best
                Fit
            </div>
            <div
                class="text-xs font-semibold text-gray-800 bg-gray-200 px-2.5 py-0.5 rounded-full flex items-center gap-1">
                <ShieldCheckIcon class="w-3.5 inline-block -mt-[1px] text-green-600" />
                {{ strategy.underlyingProduct.insurer.rating }} Rated Carrier
            </div>
            <div v-for="tag in strategy.tags" :style="`background-color: ${tag.color}22; color: ${tag.color};`"
                class="text-xs font-semibold px-2.5 py-0.5 rounded-full flex items-center gap-1">
                {{ tag.name }}
            </div>
            <div v-if="strategy.underlyingProduct.premiumFrequency"
                class="bg-violet-100 text-violet-800 text-xs font-semibold px-2.5 py-0.5 rounded-full flex items-center gap-1">
                {{ strategy.underlyingProduct.premiumFrequency }}
            </div>
            <div v-if="strategy.rolloverApproved"
                class="text-xs font-semibold px-2.5 py-0.5 rounded-full flex items-center gap-1 text-green-600">
                <CheckBadgeIcon class="w-3" /> 401(k) approved
            </div>
        </div>
    </SDPWrapper>
</template>

<script setup>
import { useQuestionnaireStore } from '../../stores/questionnaireStore';
import SDPWrapper from './SDPWrapper.vue';
import { HandThumbUpIcon, ShieldCheckIcon, CheckBadgeIcon } from '@heroicons/vue/20/solid';

const store = useQuestionnaireStore();

defineProps({
    strategy: Object,
})
</script>