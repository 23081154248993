<template>
    <SectionContainer title="Suitability Attestation" buttonText="Let's review" @nextStep="() => {
        event('suitability_attestation_complete')
        nextStep()
    }" @prevStep="prevStep" :button-disabled="!store.steps[stepIndex].complete()">
        <div class="group space-y-12">
            <div>
                <BooleanField required class="w-full" :value="financialGoals"
                    @input="e => store.setAttestation('financialGoals', e)"
                    label="After considering your net worth, source of funds, liquidity needs, and time horizon, do you believe this annuity contract effectively addresses your financial situation, insurance needs and financial objectives over the life of the contract?" />
                <ErrorMessage v-if="financialGoals === false">An annuity is an inappropriate investment if you're
                    in need of immediate liquidity.
                </ErrorMessage>
            </div>
            <div>
                <BooleanField required class="w-full" :value="previousContractsWithProducer"
                    @input="e => store.setAttestation('previousContractsWithProducer', e)"
                    label="Excluding this transaction, has your producer previously sold you any other policies or annuity contracts?" />
            </div>
            <div>
                <BooleanField required class="w-full" :value="governmentBenefits"
                    @input="e => store.setAttestation('governmentBenefits', e)"
                    label="Do you intend to apply for any means-tested government benefits and/or are you seeking qualification of any state or federal aid programs? (ex. medicaid, veterans aid and attendance benefit, etc.)" />
                <ErrorMessage v-if="governmentBenefits === true">This may greatly decrease your odds of
                    carrier approval for this policy.
                </ErrorMessage>
            </div>
            <div>
                <BooleanField required class="w-full" :value="reverseMortgage"
                    @input="e => store.setAttestation('reverseMortgage', e)" label="Do you have a reverse mortgage?" />
                <ErrorMessage v-if="reverseMortgage === true">This may greatly decrease your odds of
                    carrier approval for this policy.
                </ErrorMessage>

            </div>
            <div>
                <BooleanField required class="w-full" :value="sufficientFunds"
                    @input="e => store.setAttestation('sufficientFunds', e)"
                    label="Do you have sufficient funds available for monthly living expenses, medical expenses, and emergencies other than the funds planned for this annuity or any other annuities already owned?" />
                <ErrorMessage v-if="sufficientFunds === false">An annuity isn't a good source of emergency capital
                    due to the long-term nature of the product with applicable early surrender fees. Please ensure
                    you've considered this before purchasing.
                </ErrorMessage>
            </div>
            <div>
                <BooleanField required class="w-full" :value="surrenderCharges"
                    @input="e => store.setAttestation('surrenderCharges', e)"
                    label="An annuity is a long-term contract with substantial penalties for early surrenders and/or withdrawals. Do you understand that if you take money out of this annuity, in excess of the penalty-free surrender amount during the surrender charge period, that you will incur a surrender charge and market value adjustment (also referred to as an interest adjustment in some annuity contracts/endorsements) – if applicable?" />
                <ErrorMessage v-if="surrenderCharges === false">Please ensure that you're aware of the long-term
                    nature of an annuity, especially in terms of the surrender period and associated early surrender
                    fees.
                </ErrorMessage>

            </div>
            <div>
                <BooleanField required class="w-full" :value="futureIncomeChange"
                    @input="e => store.setAttestation('futureIncomeChange', e)"
                    label="Do you anticipate a significant change in your future income or expenses during the surrender charge period?" />
                <ErrorMessage v-if="futureIncomeChange === true">Understand that these funds will be subject to
                    surrender fees if withdrawn early. Carefully think through your liquidity needs.
                </ErrorMessage>
            </div>
            <div>
                <BooleanField required class="w-full" :value="exchanges"
                    @input="e => store.setAttestation('exchanges', e)"
                    label="Excluding this transaction, have you exchanged or replaced any other life policy or annuity contract within the last five years?" />
                <ErrorMessage v-if="exchanges === true">This may greatly decrease your odds of
                    carrier approval for this policy.
                </ErrorMessage>

            </div>
        </div>
    </SectionContainer>
</template>

<script setup>
import SectionContainer from '../components/SectionContainer.vue';
import { useApplicationStore } from '../stores/applicationStore';
import { BooleanField, ErrorMessage } from '@reviseannuity/randolph-ui';
import { toRefs } from 'vue';
import { useStep } from '../composables/useStep';
import { useRouter } from 'vue-router';
import { event } from 'vue-gtag';

const router = useRouter()
const store = useApplicationStore()
const { stepIndex, prevStep } = useStep(store)

const nextStep = () => {
    event('investment_terms_complete')
    store.saveApplicationProgress()
    router.push('/application/review')
}

const {
    financialGoals,
    previousContractsWithProducer,
    governmentBenefits,
    reverseMortgage,
    sufficientFunds,
    surrenderCharges,
    futureIncomeChange,
    exchanges
} = toRefs(store.attestations)
</script>