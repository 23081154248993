<template>
    <SectionContainer title="Existing Policies" :button-disabled="!store.steps[stepIndex].complete()" @nextStep="() => {
        event('existing_policies_complete')
        nextStep()
    }" @prevStep="prevStep" helperText="Legally, you must let us know if you have any other active annuity policies, and if you're
                replacing an existing policy with this one.">
        <div class="space-y-4">
            <BooleanField required label="Do you have any existing policies?" name="existingPolicy"
                @input="e => store.setField('existingPolicy', e)" :value="store.existingPolicy" />
            <BooleanField required label="Is this policy replacing an existing policy?" name="replacing"
                @input="e => store.setField('replacingPolicy', e)" :value="store.replacingPolicy"
                v-if="store.existingPolicy" />
            <div class="grid sm:grid-cols-2 gap-4" v-if="store.existingPolicy && store.replacingPolicy">
                <TextField required label="Company Name" name="company" :value="store.replacingPolicyData.company"
                    @input="e => store.setReplacingPolicyData('company', e)" />
                <TextField required label="Contract Number" name="contractNumber"
                    :value="store.replacingPolicyData.contractNumber"
                    @input="e => store.setReplacingPolicyData('contractNumber', e)" />
            </div>
        </div>
    </SectionContainer>
</template>

<script setup>
import { event } from 'vue-gtag';
import { BooleanField, TextField } from '@reviseannuity/randolph-ui';
import SectionContainer from '../components/SectionContainer.vue';
import { useStep } from '../composables/useStep';
import { useApplicationStore } from '../stores/applicationStore';

const store = useApplicationStore();
const { stepIndex, prevStep, nextStep } = useStep(store);
</script>