<template>
    <SDPWrapper thin>
        <SDPTitle>Strategy Overview</SDPTitle>
        <SDPDescription v-html="strategy.strategyOverview" />
        <div class="flex flex-col-reverse sm:flex-row mb-8 items-center gap-8">
            <div class="flex-1" v-if="indices">
                <IndexChart :indices="indices" />
            </div>
            <div class="text-center flex-1">
                <hr class="border-gray-3200 w-3/5 mx-auto mb-4" />
                <strong class="uppercase text-primary-600 font-bold text-sm">Our Team Says</strong>
                <p>
                    {{ strategy.ourTeamSays }}
                </p>
                <hr class="border-gray-3200 w-3/5 mx-auto mt-5" />
            </div>
        </div>
        <div>
            <div class="font-bold mb-4">Accepted Funding Sources</div>
            <div class="flex flex-wrap gap-2">
                <div v-for="source in fundingSources" v-html="source"
                    class="text-xs px-2.5 py-1 bg-gray-100 rounded-full text-gray-800 font-semibold" />
            </div>
        </div>
    </SDPWrapper>
</template>

<script setup>
import { ref } from 'vue';
import SDPWrapper from './SDPWrapper.vue';
import IndexChart from '../IndexChart.vue';
import { onMounted } from 'vue';
import SDPTitle from './SDPTitle.vue';
import SDPDescription from './SDPDescription.vue';

const {
    strategy
} = defineProps({
    strategy: Object,
})

const indices = ref(null)

onMounted(() => {
    if (!strategy?.indices?.length) return

    indices.value = [{ index: strategy.indices[0].name, percentage: strategy.index1Percentage, color: "#1454f2" }]
    if (strategy?.indices?.length > 1) {
        indices.value = [...indices.value, { index: strategy.indices[1].name, percentage: strategy.index2Percentage, color: "#052472" }]
    }
})

const fundingSources = [
    'Checking',
    'Savings',
    'Brokerage',
    '401(k)',
    '403(b)',
    'IRA',
    'Roth IRA',
    'TSP/TSA',
    'Annuity replacement'
]
</script>