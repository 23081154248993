<template>
  <RouterView v-if="store.user" />
</template>

<script setup>
import { RouterView } from 'vue-router'
import { getUserByAuth0Id } from './clients/user';
import { useApplicationStore } from './stores/applicationStore';
import { onBeforeMount, onMounted, watch } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';

const store = useApplicationStore()
const { isLoading, user } = useAuth0()

watch(isLoading, async (isLoading) => {
  if (!isLoading) {
    try {
      if (user?.value?.sub) {
        const userData = await getUserByAuth0Id(user?.value?.sub);
        store.setField('user', { ...userData, phone: user?.value?.name });
      } else {
        store.setField('user', { phone: user?.value?.name });
      }
    } catch (error) {
      store.setField('user', { auth0_id: user?.value?.sub, phone: user?.value?.name });
    }
  }
})
</script>