<template>
    <div class="mx-auto" :class="{ 'max-w-sm': !('class' in $attrs) }" v-bind="$attrs">
        <div class="bg-white shadow-lg rounded-lg p-6 sm:p-10">
            <h2 class="font-serif font-bold text-2xl sm:text-3xl mb-2" :class="[helperText ? 'mb-2' : 'mb-6']">{{ title }}
            </h2>
            <HelperText class="mb-6" v-if="helperText">{{ helperText }}</HelperText>
            <slot></slot>
            <PrimaryButton :loading="loading" v-if="!noButton" :disabled="disableNext" class="w-full"
                @click="$emit('click')" :id="buttonId">
                {{ continueText }}</PrimaryButton>
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'
import { PrimaryButton, HelperText } from '@reviseannuity/randolph-ui';
import { useQuestionnaireStore } from '../../stores/questionnaireStore';

const store = useQuestionnaireStore();
const emit = defineEmits(['click'])
const props = defineProps({
    title: String,
    helperText: String,
    disableNext: Boolean,
    enableEnterKey: {
        type: Boolean,
        default: true
    },
    noButton: {
        type: Boolean,
        default: false
    },
    continueText: {
        type: String,
        default: 'Continue'
    },
    loading: {
        type: Boolean,
        default: false
    },
    buttonId: {
        type: String,
        default: null
    }
})

const handleKeyDown = event => {
    if (!props.enableEnterKey) return
    if (event.key === 'Enter') {
        if (props.disableNext) return
        emit('click')
    }
};

onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
});

onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
});

</script>