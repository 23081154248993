<template>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-50" @close="close">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-8">
                            <div v-if="!showCal">
                                <div class="grid sm:grid-cols-2 gap-8">
                                    <button v-for="option in contactOptions" @click="option.action"
                                        class="rounded border border-gray-200 p-8 flex flex-col items-center">
                                        <div
                                            class="bg-primary-100 w-16 h-16 flex justify-center items-center rounded-full mb-2">
                                            <component :is="option.icon" class="text-primary-600 w-8 h-8" />
                                        </div>
                                        <div class="font-bold text-xl font-serif mb-2">
                                            {{ option.label }}
                                        </div>
                                        <p class="text-sm text-gray-500">{{ option.description }}</p>
                                    </button>
                                </div>
                            </div>
                            <div v-else>
                                <CalEmbed :calLink="calLink" :name="name" :email="email" :phone="phone"
                                    :dob="birthdate && new Date(birthdate).toLocaleDateString()"
                                    :strategyName="strategyName" :investmentAmount="Number(investmentAmount)"
                                    :state="state" />
                            </div>
                            <div class="flex justify-center mt-4">
                                <button @click="close" class="text-sm text-primary-600 underline">Close</button>
                            </div>

                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { showChat } from '../utils';
import { ChatBubbleBottomCenterIcon, CalendarDaysIcon } from '@heroicons/vue/24/outline';
import CalEmbed from './CalEmbed.vue';
import { ref } from 'vue';
import { useQuestionnaireStore } from '../stores/questionnaireStore';

defineProps({
    open: Boolean,
    birthdate: String,
    strategyName: String,
    investmentAmount: Number,
    state: String,
    name: String,
    email: String,
    phone: String,
    calLink: String
})
const emit = defineEmits(['close'])
const store = useQuestionnaireStore()
const showCal = ref(false)

const close = () => {
    emit('close')
    showCal.value = false
}

const contactOptions = [
    {
        label: 'Chat Now',
        description: 'Chat with our highly trained AI assistant available around-the-clock. ',
        action: () => {
            close()
            showChat()
        },
        icon: ChatBubbleBottomCenterIcon
    },
    {
        label: 'Book a Call',
        description: 'Book a call with an expert on our team.',
        action: () => showCal.value = true,
        icon: CalendarDaysIcon
    },
]
</script>