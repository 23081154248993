<template>
    <li :key="name" class="flex items-center">
        <CheckCircleIcon v-if="stepComplete" class="w-4 text-green-600 mr-1 sm:mr-2" />
        <div v-else :class="[
            'w-[12.8px] h-[12.8px] rounded-full border border-gray-400 ml-[1px] mr-1 sm:mr-2',
            active && '!border-primary',
        ]" />
        <button @click="$emit('click')" :class="[
            'whitespace-nowrap rounded-md text-sm leading-6 text-gray-500 hover:text-primary-950',
            stepComplete && 'font-semibold text-primary-950',
            active && 'text-primary-600 font-semibold',
        ]">
            {{ name }}
        </button>
    </li>
</template>

<script setup>
import { CheckCircleIcon } from '@heroicons/vue/20/solid';
import { computed } from 'vue';
import { useApplicationStore } from '../stores/applicationStore';

const store = useApplicationStore();

defineEmits(['click']);
const props = defineProps(['url', 'name', 'i', 'active']);

const stepComplete = computed(() => store.steps[props.i].complete())
</script>