import { defineStore } from "pinia";
import { formatMoney, formatApplicationText, generateId, unqualifiedFundLocations } from "../utils.js";
import { updateApplicationForUser, updateUser } from "../clients/user.js";
import { applicationSubmittedEvents } from "../eventTagging.js";

const defaultPerson = {
    gender: 'male',
    firstName: '',
    middleName: '',
    lastName: '',
    ssn: '',
    dob: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: 'Alabama',
    zip: ''
}

const defaultBeneficiary = {
    id: generateId(),
    type: 'individual',
    status: 'primary',
    contingency: 'per-capita',
    ...defaultPerson,
    relationship: 'child',
    percentage: 100
}

const defaultFundingSource = {
    id: generateId(),
    fundsLocation: '',
    institution: '',
    amount: 0,
}

export const useApplicationStore = defineStore('application', {
    state: () => ({
        loading: true,
        applicationId: null,
        errorMessage: null,
        submittingApplication: false,
        applicationSubmitted: false,
        submissionErrors: [],
        investmentAmount: 0,
        monthlyContributions: 0,
        personalInformation: {
            gender: null,
            name: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            state: 'Alabama',
            zip: '',
            birthdate: '',
            ssn: '',
            formOfIdentification: 'drivers-license',
            idNumber: '',
            issuingState: 'Alabama',
            expirationDate: '',
        },
        fundingSourceType: 'qualified',
        fundingSources: [],
        ownerType: 'individual',
        owner: {},
        jointOwner: {
            sameAddress: true, state: 'Alabama', ssn: '',
            formOfIdentification: 'drivers-license',
            idNumber: '',
            issuingState: 'Alabama',
            expirationDate: '',
        },
        annuitantType: null,
        otherAnnuitant: {},
        beneficiaries: [defaultBeneficiary],
        existingPolicy: null,
        replacingPolicy: null,
        replacingPolicyData: { company: null, contractNumber: null },
        householdIncome: 0,
        householdExpenses: 0,
        householdLiabilities: 0,
        householdInvestments: [],
        taxFilingStatus: 'single',
        householdInvestmentDetails: {
            'stocks-bonds': { value: 0 },
            'annuities': { value: 0 },
            'mutual-funds': { value: 0 },
            'cds': { value: 0 },
            'money-market': { value: 0 },
            'checking-savings': { value: 0 },
            'pension-401k': { value: 0 },
            'life-insurance': { value: 0 },
            'real-estate': { value: 0 },
            'reverse-mortgage': { value: 0 },
            'limited-partnership': { value: 0 },
        },
        engagementLetterAgreed: false,
        savingApplication: false,
        attestations: {
            financialGoals: null,
            previousContractsWithProducer: null,
            governmentBenefits: null,
            reverseMortgage: null,
            sufficientFunds: null,
            surrenderCharges: null,
            futureIncomeChange: null,
            exchanges: null,
        },
        strategy: null,
        wereYouReferred: null,
        referrer: null,
        referringPartner: ''
    }),
    getters: {
        steps(state) {
            return [
                {
                    name: 'Welcome',
                    url: 'welcome',
                    complete: () => true
                },

                {
                    name: 'Personal Information',
                    url: 'personal-information',
                    complete: () => (
                        state.personalInformation.gender &&
                        state.personalInformation.name &&
                        state.personalInformation.email &&
                        state.personalInformation.phone &&
                        state.personalInformation.address &&
                        state.personalInformation.city &&
                        state.personalInformation.state &&
                        state.personalInformation.zip &&
                        state.personalInformation.birthdate
                    )
                },
                {
                    name: 'Verification',
                    url: 'verification',
                    complete: () => (state.personalInformation.ssn &&
                        state.personalInformation.formOfIdentification &&
                        state.personalInformation.idNumber &&
                        state.personalInformation.issuingState &&
                        state.personalInformation.expirationDate)
                },
                {
                    name: 'Funding Sources',
                    url: 'funding-sources',
                    complete: () => {
                        return state.fundingSources.length &&
                            state.fundingSources.every(source => source.institution && source.amount && (Object.keys(unqualifiedFundLocations).includes(source.fundsLocation) ? source.accountNumber && source.routingNumber : true)) &&
                            state.totalFunded() == state.investmentAmount
                    }
                },
                {
                    name: 'Ownership',
                    url: 'ownership',
                    complete: () => {
                        if (state.ownerType == 'individual') {
                            return state.owner.gender && state.owner.name && state.owner.ssn && state.owner.address && state.owner.city && state.owner.state && state.owner.zip
                        }

                        if (state.ownerType == 'joint') {
                            return state.owner.gender && state.owner.name && state.owner.ssn && state.owner.address && state.owner.city && state.owner.state && state.owner.zip &&
                                state.jointOwner.gender && state.jointOwner.firstName && state.jointOwner.lastName && state.jointOwner.ssn && state.jointOwner.dob && state.jointOwner.idNumber && state.jointOwner.expirationDate && (state.jointOwner.sameAddress ? true : state.jointOwner.address && state.jointOwner.city && state.jointOwner.state && state.jointOwner.zip)
                        }
                    }
                },
                {
                    name: 'Annuitant',
                    url: 'annuitant',
                    complete: () => {
                        if (state.annuitantType == 'self') {
                            return state.owner.gender && state.owner.name && state.owner.ssn && state.owner.address && state.owner.city && state.owner.state && state.owner.zip
                        }

                        if (state.annuitantType == 'spouse-joint') {
                            return state.owner.gender && state.owner.name && state.owner.ssn && state.owner.address && state.owner.city && state.owner.state && state.owner.zip &&
                                state.jointOwner.gender && state.jointOwner.firstName && state.jointOwner.lastName && state.jointOwner.ssn && state.jointOwner.dob && state.jointOwner.idNumber && state.jointOwner.expirationDate && (state.jointOwner.sameAddress ? true : state.jointOwner.address && state.jointOwner.city && state.jointOwner.state && state.jointOwner.zip)
                        }

                        if (state.annuitantType == 'other') {
                            return state.otherAnnuitant.firstName && state.otherAnnuitant.lastName && state.otherAnnuitant.ssn && state.otherAnnuitant.address && state.otherAnnuitant.city && state.otherAnnuitant.state && state.otherAnnuitant.zip
                        }
                    }
                },
                {
                    name: 'Beneficiaries',
                    url: 'beneficiaries',
                    complete: () => {
                        return state.beneficiaries.length &&
                            // state.beneficiariesPercentage == 100 &&
                            state.beneficiaries.every(beneficiary => (
                                beneficiary.firstName &&
                                beneficiary.lastName &&
                                beneficiary.address &&
                                beneficiary.city &&
                                beneficiary.state &&
                                beneficiary.zip &&
                                beneficiary.dob &&
                                beneficiary.relationship
                            ))
                    }
                },
                {
                    name: 'Existing Policies',
                    url: 'existing-policies',
                    complete: () => {
                        if (state.existingPolicy === null) return false

                        if (state.existingPolicy && state.replacingPolicy) {
                            return state.replacingPolicyData.company && state.replacingPolicyData.contractNumber
                        } else {
                            return true
                        }
                    }
                },
                {
                    name: 'Household Information',
                    url: 'household-information',
                    complete: () => {
                        return (
                            state.householdIncome > 0 &&
                            state.householdExpenses > 0 &&
                            state.householdInvestments.length > 0 &&
                            state.householdInvestments.every(investment => state.householdInvestmentDetails[investment]?.value) &&
                            Object.values(state.householdInvestmentDetails).some(detail => detail.liquidating)
                        )
                    }
                },
                {
                    name: 'Suitability Attestation',
                    url: 'suitability-attestation',
                    complete: () => (
                        state.attestations.financialGoals !== null &&
                        state.attestations.previousContractsWithProducer !== null &&
                        state.attestations.governmentBenefits !== null &&
                        state.attestations.reverseMortgage !== null &&
                        state.attestations.sufficientFunds !== null &&
                        state.attestations.surrenderCharges !== null &&
                        state.attestations.futureIncomeChange !== null &&
                        state.attestations.exchanges !== null
                    )
                }
            ]
        },
        formattedInvestmentAmount(state) {
            return formatMoney(state.investmentAmount)
        },
        progress(state) {
            return state.steps.filter(step => step.complete()).length / state.steps.length * 100
        },
        totalFunded(state) {
            return () => state.fundingSources.reduce((a, b) => a + Number(b.amount), 0)
        },
        beneficiariesPercentage(state) {
            return state.beneficiaries.reduce((a, b) => a + Number(b.percentage), 0)
        },
        fundingIsQualified(state) {
            return state.fundingSources.some(source => [].includes(source.fundsLocation))
        },
        setAttestation(state) {
            return (key, value) => state.attestations[key] = value
        },
        readyForSubmission(state) {
            return state.steps.every(step => step.complete())
        }
    },
    actions: {
        fundingSource(id) {
            return this.fundingSources.find(source => source.id === id)
        },
        setField(key, value) {
            this[key] = value
        },
        setPersonalInformation(key, value) {
            this.personalInformation[key] = value
        },
        addFundingSource() {
            this.fundingSources.push({ ...defaultFundingSource, id: generateId() })
        },
        removeFundingSource(id) {
            this.fundingSources = this.fundingSources.filter(source => source.id !== id)
        },
        updateFundingSource(id, key, value) {
            const source = this.fundingSources.find(source => source.id === id)
            source[key] = value
        },
        updateOwner(key, value) {
            this.owner[key] = value
        },
        updateJointOwner(key, value) {
            this.jointOwner[key] = value
        },
        updateOtherAnnuitant(key, value) {
            this.otherAnnuitant[key] = value
        },
        addBeneficiary() {
            this.beneficiaries.push({
                ...defaultBeneficiary,
                id: generateId(),
                status: this.beneficiaries.length === 0 ? 'primary' : 'contingent',
            })
        },
        updateBeneficiary(id, key, value) {
            this.beneficiaries = this.beneficiaries.map(beneficiary => {
                if (beneficiary.id === id) {
                    return { ...beneficiary, [key]: value }
                } else {
                    return beneficiary
                }
            })
        },
        removeBeneficiary(index) {
            this.beneficiaries.splice(index, 1)
        },
        setReplacingPolicyData(key, value) {
            this.replacingPolicyData[key] = value
        },
        setHouseholdInvestmentDetails(type, key, value) {
            this.householdInvestmentDetails[type][key] = value
        },
        validatePerson(person, title) {
            let errors = []
            if (!person.gender) errors.push(`Must specify a gender for ${title}`)
            if (!person.firstName) errors.push(`Must specify first name for ${title}`)
            if (!person.lastName) errors.push(`Must specify last name for ${title}`)
            if (!person.ssn) errors.push(`Must specify SSN for ${title}`)
            if (!person.address) errors.push(`Must specify address for ${title}`)
            if (!person.city) errors.push(`Must specify city for ${title}`)
            if (!person.state) errors.push(`Must specify state for ${title}`)
            if (!person.zip) errors.push(`Must specify zip for ${title}`)
            return errors
        },
        validateForm() {
            const errors = []


            if (!this.fundingSources.length) errors.push('You must provide at least one funding source.')

            this.fundingSources.forEach((source, i) => {
                if (!source.institution) errors.push(`You must provide the institution for funding source ${i + 1}`)
                if (!source.amount) errors.push(`You must provide an amount for funding source ${i + 1}`)
            })

            let ownerErrors = []
            if (["individual", "joint"].includes(this.ownerType)) {
                ownerErrors = this.validatePerson(this.owner, "owner")
            }
            if (this.ownerType == "joint") {
                ownerErrors = [...ownerErrors, ...this.validatePerson(this.jointOwner, "joint owner")]
            }

            // TODO: Currently not even tracking trust/corp data
            // if (this.ownerType == "trust-corp") {
            //     ownerErrors = this.validateCorporation(this.owner, "owner")
            // }
            if (ownerErrors.length) errors.push(...ownerErrors)

            if (!this.beneficiaries.length) errors.push('You must provide at least one beneficiary.')
            if (this.beneficiaries.length) {
                this.beneficiaries.forEach(beneficiary => {
                    this.errors.push(...this.validatePerson(beneficiary))
                })
            }

            if (this.beneficiaries.reduce((a, b) => a + b.percentage, 0) !== 100) {
                errors.push('Total percentages of all beneficiaries must equal 100%.')
            }

            if (this.annuitantType == "joint" && this.ownerType !== "joint") errors.push("Please ensure both the owner and annuitant are set to joint.")
            if (this.annuitantType == "other") {
                const annuitantErrors = this.validatePerson(this.otherAnnuitant, "annuitant")
            }

            if (this.replacingPolicy) {
                if (!this.replacingPolicyData.company) errors.push("You must provide the company name of the policy you're replacing.")
                if (!this.replacingPolicyData.contractNumber) errors.push("You must provide the policy number name of the policy you're replacing.")
            }

            if (!this.householdIncome) errors.push("You must provide your household income.")
            if (!this.householdExpenses) errors.push("You must provide your monthly household expenses.")
            if (this.householdInvestments) {
                this.householdInvestments.forEach(investment => {
                    if (this.householdInvestmentDetails[investment].value) errors.push(`You must provide the value of your ${investment}`)
                })
            }

            return { errors }
        },
        async submitApplication() {
            this.submittingApplication = true

            try {

                await updateApplicationForUser(this.user.auth0_id, {
                    json: this.$state,
                    response: formatApplicationText(this.$state),
                    status: 'submitted'
                })
                await updateUser(this.user.auth0_id, {
                    status: 'application_submitted',
                    first_touch_attribution: window.reviseTracking?.getFirstTouchAttribution() || '',
                    last_touch_attribution: window.reviseTracking?.getLastTouchAttribution() || '',
                })

                applicationSubmittedEvents(this.investmentAmount, this.applicationId, this.$state)

                this.applicationSubmitted = true
            } catch (error) {
                alert('Failed to submit application. ' + error.message)
            } finally {
                this.submittingApplication = false
            }
        },
        async saveApplicationProgress() {
            this.savingApplication = true
            await updateApplicationForUser(this.user.auth0_id, { json: this.$state })
            this.savingApplication = false
        }
    }
})