<template>
    <SectionContainer title="Personal Information" :open="true" @nextStep="() => {
        nextStep()
        event('personal_information_complete')
    }" @prevStep="prevStep" :buttonDisabled="!store.steps[stepIndex].complete()" helperText="This information is required by the carrier to apply for an annuity. Your information will only be sent
                directly to the carrier.">
        <div class="grid grid-cols-5 gap-4 sm:gap-8">
            <div class="grid grid-cols-5 gap-4 col-span-full">
                <div class="col-span-full">
                    <RadioField :inline="true" label="Gender" required :name="`${name}_gender`"
                        :value="store.personalInformation.gender"
                        :options="[{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }]"
                        @input="v => store.setPersonalInformation('gender', v)" />
                </div>
                <TextField label="Name" required name="full-name" class="col-span-full"
                    @input="v => store.setPersonalInformation('name', v)" :value="store.personalInformation.name" />
                <TextField label="Email" required name="email" type="email" class="col-span-full sm:col-span-3"
                    @input="v => store.setPersonalInformation('email', v)" :value="store.personalInformation.email" />
                <PhoneField label="Phone" required name="phone" type="phone" class="col-span-full sm:col-span-2"
                    @input="v => store.setPersonalInformation('phone', v)" :value="store.personalInformation.phone" />
                <TextField label="Street address" required name="address" class="col-span-full"
                    @input="v => store.setPersonalInformation('address', v)"
                    :value="store.personalInformation.address" />
                <TextField label="Apt, suite, etc. (optional)" name="address_2" class="col-span-full"
                    @input="v => store.setPersonalInformation('address_2', v)"
                    :value="store.personalInformation.address_2" />
                <TextField label="City" required name="city" class="col-span-full sm:col-span-3"
                    @input="v => store.setPersonalInformation('city', v)" :value="store.personalInformation.city" />
                <div class="col-span-full sm:col-span-2">
                    <StateSelectField label="State" required name="state"
                        @input="v => store.setPersonalInformation('state', v)"
                        :value="store.personalInformation.state" />
                </div>
                <TextField label="Zip" required name="zip" class="col-span-2"
                    @input="v => store.setPersonalInformation('zip', v)" :value="store.personalInformation.zip" />
            </div>
            <DateField label="Date of Birth" required name="dob" class="col-span-full sm:col-span-2"
                @input="v => store.setPersonalInformation('birthdate', v)"
                :value="store.personalInformation.birthdate" />
        </div>
    </SectionContainer>
</template>

<script setup>
import SectionContainer from '../components/SectionContainer.vue';
import {
    StateSelectField,
    TextField,
    RadioField,
    PhoneField,
    DateField,
} from '@reviseannuity/randolph-ui';

import { useApplicationStore } from '../stores/applicationStore';
import { useStep } from '../composables/useStep';
import { event } from 'vue-gtag';

const store = useApplicationStore()

const { stepIndex, nextStep, prevStep } = useStep(store)
</script>