import { defineStore } from 'pinia'

export const REGISTRATION_STEPS = {
    INFO: 'registration_info',
    LIQUID_FUNDS: 'registration_liquid_funds',
    QUALIFIED_INVESTMENT_AMOUNT: 'registration_qualified_investment_amount',
    ROLLOVER_BOOKING: 'registration_rollover_booking',
    FUNDING: 'registration_funding',
    INVESTMENT_AMOUNT: 'registration_investment_amount',
    INITIAL_PREMIUM_AMOUNT: 'registration_initial_premium_amount'
}

export const useRegistrationStore = defineStore('registration', {
    state: () => ({
        auth0Id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        strategyName: '',
        investmentAmount: 0,
        state: '',
        birthdate: null,
        age: '',
        zip: '',
        optIn: false,
        submitting: false,
        liquidFunds: 0,
        activeStep: REGISTRATION_STEPS.INFO,
        fundingSource: '',
        focus: null,
        steps: [
            REGISTRATION_STEPS.INFO,
            REGISTRATION_STEPS.FUNDING,
            REGISTRATION_STEPS.LIQUID_FUNDS,
            REGISTRATION_STEPS.QUALIFIED_INVESTMENT_AMOUNT,
            REGISTRATION_STEPS.ROLLOVER_BOOKING,
            REGISTRATION_STEPS.INITIAL_PREMIUM_AMOUNT,
        ]
    }),
    getters: {
        percentage: (state) => {
            switch (state.activeStep) {
                case REGISTRATION_STEPS.INFO:
                    return 70;
                case REGISTRATION_STEPS.FUNDING:
                    return 80;
                case REGISTRATION_STEPS.LIQUID_FUNDS:
                    return 90;
                case REGISTRATION_STEPS.INITIAL_PREMIUM_AMOUNT:
                    return 100;
                case REGISTRATION_STEPS.INVESTMENT_AMOUNT:
                    return 100;
                case REGISTRATION_STEPS.QUALIFIED_INVESTMENT_AMOUNT:
                    return 90;
                case REGISTRATION_STEPS.ROLLOVER_BOOKING:
                    return 100;
                default:
                    return 70;
            }
        },
        previousStep: (state) => {
            switch (state.activeStep) {
                case REGISTRATION_STEPS.LIQUID_FUNDS:
                    return REGISTRATION_STEPS.FUNDING;

                case REGISTRATION_STEPS.INVESTMENT_AMOUNT:
                    return REGISTRATION_STEPS.LIQUID_FUNDS

                case REGISTRATION_STEPS.QUALIFIED_INVESTMENT_AMOUNT:
                    return REGISTRATION_STEPS.FUNDING;

                case REGISTRATION_STEPS.ROLLOVER_BOOKING:
                    return REGISTRATION_STEPS.QUALIFIED_INVESTMENT_AMOUNT

                case REGISTRATION_STEPS.INITIAL_PREMIUM_AMOUNT:
                    return REGISTRATION_STEPS.FUNDING

                default:
                    return false
            }
        },
    },
    actions: {
        setField(field, value) {
            this[field] = value;
        },
        setActiveStep(step) {
            this.activeStep = step;
            this.router.push({ name: step });
        }
    }
})
