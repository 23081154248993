<template>
    <QuestionnaireSection title="How much are you looking to invest?"
        helperText="This will help us customize your experience. You can set an exact amount later." @click="nextStep"
        :disableNext="!store.validateStep('investment-amount')" buttonId="investmentAmountContinue">
        <div class="mb-8">
            <MoneyField :value="store.investmentAmount" @input="store.setField('investmentAmount', $event)" />
        </div>
        <template v-slot:footer>
            <div class="mt-2">
                <SecondaryButton block @click="skip">{{ window.notSureYet ? "I'm not sure yet" : "I'm not sure" }}
                </SecondaryButton>
            </div>
        </template>
    </QuestionnaireSection>
</template>

<script setup>
import { onMounted } from 'vue';
import QuestionnaireSection from './QuestionnaireSection.vue';
import { useQuestionnaireStore } from '../../stores/questionnaireStore';
import { event } from 'vue-gtag';
import { QUESTIONNAIRE_STEPS } from '../../utils';
import { useRouter } from 'vue-router';
import { MoneyField, SecondaryButton } from '@reviseannuity/randolph-ui';

const router = useRouter()
const store = useQuestionnaireStore();

const nextStep = () => {
    event('investment_amount_selected', {
        investment_amount: store.investmentAmount
    })

    router.push(store.nextStep())
}

const skip = () => {
    store.setField('investmentAmount', 100000)
    router.push(store.nextStep())
}

onMounted(() => {
    store.setStep(QUESTIONNAIRE_STEPS.INVESTMENT_AMOUNT)
    event('page_view', {
        page_title: 'Master App Questionnaire - Investment Amount',
        page_location: window.location.href,
        page_path: window.location.pathname
    })
})
</script>