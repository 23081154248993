import { defineStore } from "pinia";
import { getAllStrategies } from "../clients/strategies";
import { QUESTIONNAIRE_STEPS } from "../utils";
import { productTypeOptions, returnsOptions } from "../data/strategyList";
import { questionnaireCompletedEvents } from "../eventTagging";




export const useQuestionnaireStore = defineStore('questionnaire', {
    state: () => ({
        steps: {
            [QUESTIONNAIRE_STEPS.ZIP]: {
                validate: (state) => state.zipCode !== null && state.zipCode.length === 5,
                next: () => QUESTIONNAIRE_STEPS.BIRTHDATE
            },
            [QUESTIONNAIRE_STEPS.BIRTHDATE]: {
                validate: (state) => state.age || (state.birthdate !== null && state.birthdate instanceof Date),
                next: (state) => state.age > 40 ? QUESTIONNAIRE_STEPS.FOCUS : QUESTIONNAIRE_STEPS.RISK_TOLERANCE
            },
            [QUESTIONNAIRE_STEPS.RISK_TOLERANCE]: {
                validate: (state) => state.riskTolerance !== null,
                next: () => QUESTIONNAIRE_STEPS.INVESTMENT_AMOUNT
            },
            [QUESTIONNAIRE_STEPS.INVESTMENT_AMOUNT]: {
                validate: (state) => state.investmentAmount > 0,
                next: () => QUESTIONNAIRE_STEPS.STRATEGY_LIST
            },
            [QUESTIONNAIRE_STEPS.FOCUS]: {
                validate: (state) => state.focus !== null,
                next: (state) => {
                    if (state.focus === 'growth') return QUESTIONNAIRE_STEPS.RISK_TOLERANCE
                    return QUESTIONNAIRE_STEPS.STRATEGY_LIST
                }
            },
            [QUESTIONNAIRE_STEPS.STRATEGY_LIST]: {
                validate: () => true,
                next: () => QUESTIONNAIRE_STEPS.STRATEGY_DETAIL
            },
            [QUESTIONNAIRE_STEPS.STRATEGY_DETAIL]: {
                validate: () => true,
                next: () => null
            }
        },
        activeStep: 'zip',
        previousStep: null,
        state: null,
        zipCode: '',
        birthdate: null,
        investmentAmount: 0,
        riskTolerance: null,
        fundingSource: null,
        strategies: [],
        loadingStrategies: true,
        minVolatility: 0,
        maxVolatility: 0,
        loading: true,
        strategyLoadingError: null,
        age: null,
        focus: null,
        sortedResults: [],
        sortBy: 'bestMatch',
        minimumRating: 0,
        productType: productTypeOptions.map(option => option.value),
        useCase: null,
        returns: returnsOptions.map(option => option.value),
        showBirthdateChecks: false
    }),
    getters: {
        totalSteps(state) {
            return Object.keys(state.steps).length
        },
        progress(state) {
            const stepKeys = Object.keys(state.steps)
            const activeStepIndex = stepKeys.indexOf(state.activeStep)
            return (activeStepIndex + 1) / stepKeys.length * 80;
        },
        isLastStep(state) {
            return state.activeStep === QUESTIONNAIRE_STEPS.STRATEGY_DETAIL;
        }
    },
    actions: {
        setField(field, value) {
            this[field] = value;
        },
        nextStep() {
            const nextStep = this.steps[this.activeStep].next(this)
            if (!nextStep) throw Error('Step not found: ' + step)
            this.setStep(nextStep)
            return this.activeStep
        },
        setStep(step) {
            this.activeStep = step
        },
        validateStep(step) {
            if (!this.steps[step]) throw new Error(`No validation for step ${step} found!`)
            return this.steps[step].validate(this)
        },
        async fetchStrategies() {
            const startTime = Date.now()
            this.strategyLoadingError = null
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100vh';
            this.loadingStrategies = true

            try {
                const res = await getAllStrategies({
                    state: this.state,
                    age: this.age,
                    riskTolerance: this.riskTolerance,
                    investmentAmount: this.investmentAmount,
                    focus: this.focus,
                })

                this.minVolatility = Math.min(...res.map(s => Number(s.volatility)))
                this.maxVolatility = Math.max(...res.map(s => Number(s.volatility)))
                const endTime = Date.now()
                const duration = endTime - startTime
                this.strategies = res
                this.sortAndFilter()
                await new Promise(resolve => setTimeout(resolve, 4500 - duration))
                questionnaireCompletedEvents(this.investmentAmount)
                this.loadingStrategies = false
                document.body.style.overflow = 'auto';
                document.body.style.height = 'auto';
            } catch (error) {
                this.strategyLoadingError = error
                document.body.style.overflow = 'auto';
                document.body.style.height = 'auto';
                this.loadingStrategies = false
            }

        },
        setProductType(type, value) {
            if (value) {
                this.productType = [...this.productType, type]
            } else {
                this.productType = this.productType.filter(t => t !== type)
            }
        },
        setReturns(type, value) {
            if (value) {
                this.returns = [...this.returns, type]
            } else {
                this.returns = this.returns.filter(t => t !== type)
            }
        },
        resetFilters() {
            this.productType = productTypeOptions.map(option => option.value)
            this.returns = returnsOptions.map(option => option.value)
            this.minimumRating = 0
            this.useCase = null
        },
        sortAndFilter() {
            const strategies = this.strategies.slice(1)
                .filter(strategy => {
                    return this.hasMinimumRating(strategy, this.minimumRating) && this.hasProductType(strategy, this.productType) && this.hasUseCase(strategy, this.useCase) && this.hasReturnType(strategy, this.returns)
                })
                .sort((a, b) => {
                    switch (this.sortBy) {
                        case 'bestMatch':
                            return b.matchScore - a.matchScore
                        case 'highestRate':
                            return b.historicalRate - a.historicalRate
                        case 'lowestVolatility':
                            return a.volatility - b.volatility
                        case 'highestRating':
                            return b.underlyingProduct.insurer.rating - a.underlyingProduct.insurer.rating
                        case 'mostReviews':
                            return b.reviews.length - a.reviews.length
                        default:
                            return 0
                    }
                })
            this.sortedResults = strategies
        },
        hasMinimumRating(strategy, minimumRating) {
            if (!minimumRating) return true

            const strategyRating = strategy.reviews.reduce((acc, review) => acc + review.rating, 0) / strategy.reviews.length
            return strategyRating >= minimumRating
        },
        hasProductType(strategy, productType) {
            let strategyType = strategy.type
            if (['income', 'hybrid'].includes(strategy.type)) strategyType = 'income'
            if (['growth', 'myga'].includes(strategy.type)) strategyType = 'growth'
            return productType.includes(strategyType)
        },
        hasUseCase(strategy, useCase) {
            if (!useCase) return true

            switch (useCase) {
                case '401k':
                    return strategy.rolloverApproved
                default:
                    return strategy.tags.map(tag => tag.name.toLowerCase()).includes(useCase.toLowerCase())
            }
        },
        hasReturnType(strategy, returns) {
            if (!returns.length) return false

            return strategy?.returnType?.map(type => type.toLowerCase())
                .some(type => returns.includes(type))
        }
    }
}) 