<template>
    <div class="w-full h-2 rounded-full bg-gradient-to-r from-lime-600 via-yellow-400 to-red-700 relative">
        <div class="absolute w-0 h-0 
      border-l-[6px] border-l-transparent
      border-t-[9px] border-t-gray-900
      border-r-[6px] border-r-transparent bottom-[120%] -ml-[6px]" :style="{ left: left + '%' }"></div>
        <span
            class="absolute left-0 top-[110%] text-[0.6em] uppercase font-semibold text-gray-600 tracking-tighter">Stable</span>
        <span
            class="absolute text-right right-0 top-[110%] text-[0.6em] uppercase font-semibold text-gray-600 tracking-tighter">Aggressive</span>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    value: {
        type: Number,
        required: true,
        default: 50
    },
    min: {
        type: Number,
        required: true,
        default: 0
    },
    max: {
        type: Number,
        required: true,
        default: 100
    }
})

const left = computed(() => ((props.value - props.min) / (props.max - props.min)) * 100)
</script>