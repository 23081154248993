<template>
    <SectionContainer title="Ownership" :button-disabled="!store.steps[stepIndex].complete()" @nextStep="() => {
        event('ownership_complete')
        nextStep()
    }" @prevStep="prevStep" helperText="The owner of an annuity is the person or entity that purchases the annuity contract and holds the
            contractual rights. They pay the initial premium to the insurance company and
            have the authority to make withdrawals, change the beneficiaries named in the contract and terminate the
            annuity.">
        <div class="space-y-8">
            <RadioField required :inline="true" label="Who will own this annuity?" name="ownerType"
                :options="ownerTypes" @input="e => store.setField('ownerType', e)" :value="store.ownerType" />
            <PersonInput name="jointOwners" label="Joint Owner (Spouse)" v-if="['joint'].includes(store.ownerType)"
                @update="({ key, value }) => store.updateJointOwner(key, value)" :value="store.jointOwner"
                :toggleAddress="true" :showAddress="!store.jointOwner.sameAddress" ssnRequired
                @setShowAddress="e => store.updateJointOwner('sameAddress', !e)" showId />
            <CorporationInput name="owner" label="Owner (Trust/Corporation)"
                v-if="['trust-corp'].includes(store.ownerType)"
                @update="({ key, value }) => store.updateOwner(key, value)" :value="store.owner" />
        </div>
    </SectionContainer>
</template>

<script setup>
import { PersonInput, RadioField } from '@reviseannuity/randolph-ui'
import CorporationInput from '../components/CorporationInput.vue'
import SectionContainer from '../components/SectionContainer.vue';
import { useApplicationStore } from '../stores/applicationStore';
import { onMounted } from 'vue';
import { useStep } from '../composables/useStep';
import { event } from 'vue-gtag';

const store = useApplicationStore();
const { nextStep, prevStep, stepIndex } = useStep(store)

const ownerTypes = [
    {
        label: 'Me',
        value: 'individual',
        tooltip: "Choose this option if you are the sole owner and maintainer of this annuity."
    },
    {
        label: 'Joint Owners',
        value: 'joint',
        tooltip: "Choose this option if you wish to co-own and maintain this annuity with your spouse.",
        disabled: store.fundingIsQualified
    },
    // {
    //     label: 'Trust or Corporation',
    //     value: 'trust-corp',
    //     tooltip: "This is a very uncommon option. Only choose this if you have a <strong>pre-established</strong> trust or corporation, with a corresponding EIN, who will own and maintain the annuity."
    // },
]

onMounted(() => {
    store.setField('owner', {
        ...store.personalInformation,
        firstName: store.personalInformation.name.split(' ')[0],
        lastName: store.personalInformation.name.split(' ')[1]
    })
})
</script>