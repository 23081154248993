<template>
    <div style="width:100%;height:100%;overflow:scroll" id="my-cal-inline"></div>
</template>

<script setup>
import { onMounted } from 'vue';
import { formatMoney } from '../utils';

const emits = defineEmits(['booking-successful']);
const props = defineProps(['calLink', 'name', 'email', 'investmentAmount', 'strategyName', 'state', 'phone', 'dob']);

onMounted(() => {
    (function (C, A, L) { let p = function (a, ar) { a.q.push(ar); }; let d = C.document; C.Cal = C.Cal || function () { let cal = C.Cal; let ar = arguments; if (!cal.loaded) { cal.ns = {}; cal.q = cal.q || []; d.head.appendChild(d.createElement("script")).src = A; cal.loaded = true; } if (ar[0] === L) { const api = function () { p(api, arguments); }; const namespace = ar[1]; api.q = api.q || []; if (typeof namespace === "string") { cal.ns[namespace] = cal.ns[namespace] || api; p(cal.ns[namespace], ar); p(cal, ["initNamespace", namespace]); } else p(cal, ar); return; } p(cal, ar); }; })(window, "https://app.cal.com/embed/embed.js", "init");
    Cal("init", { origin: "https://cal.com" });

    Cal("inline", {
        elementOrSelector: "#my-cal-inline",
        calLink: props.calLink,
        layout: "month_view",
        config: {
            name: props.name,
            email: props.email,
            strategy: props.strategyName,
            amount: formatMoney(props.investmentAmount),
            state: props.state,
            smsReminderNumber: props.phone,
            dob: props.dob
        }
    });

    Cal("ui", { "theme": "light", "styles": { "branding": { "brandColor": "#1454f2" } }, "hideEventTypeDetails": false, "layout": "month_view" });

    Cal("on", {
        action: "bookingSuccessful",
        callback: (e) => {
            const { data } = e.detail;
            if (data.confirmed) {
                emits('booking-successful', data);
            }
        }
    })
})
</script>