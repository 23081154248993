<template>
    <QuestionnaireSection title="Confirm your investment amount." :loading="store.submitting"
        helperText="Liquidity is an important factor to consider when purchasing an annuity. Based on financial planning research and your risk tolerance, we'll help you determine the correct amount to fund."
        @click="showConfirmationModal = true" class="max-w-3xl w-full" v-if="store.liquidFunds"
        :continueText="`Continue with ${formatMoney(recommendedAmount)}`" :enableEnterKey="false">
        <div class="mb-12 sm:mb-8">
            <h2 class="text-xl text-primary-600 font-serif font-bold mb-6 text-center">I have <span
                    class="px-1.5 py-0.5 bg-primary-50 rounded">{{ store.liquidFunds >= 5000000
            ?
            'over' : '' }}
                    {{ formatMoney(store.liquidFunds) }}</span>
                in total liquid assets.
            </h2>
            <SliderField :defaultValue="store.liquidFunds" min="21739" max="5000000" step="10000"
                @input="v => store.liquidFunds = v" :ticks="ticks" />

            <div class="relative mt-12 pt-16 sm:pt-20">
                <div class="absolute sm:left-8 bottom-10 sm:bottom-20 text-gray-800 font-semibold text-sm sm:text-base">
                    {{ formatMoney(store.liquidFunds * 0.8) }}
                </div>
                <div class="absolute left-0 -top-4 sm:top-0 flex justify-center w-full">
                    <div
                        class="shadow bg-primary-600 text-white text-center justify-center items-center rounded inline-flex px-3 py-1.5 sm:px-6 sm:py-2 flex-col">
                        <div class="text-primary-300 text-[0.7rem] leading-0 mb-0 uppercase">Recommended Annuity Amount
                        </div>
                        <div class="text-2xl sm:text-3xl font-serif font-bold">{{ formatMoney(recommendedAmount) }}
                        </div>
                    </div>
                </div>
                <div
                    class="absolute right-0 bottom-10 sm:right-8 sm:bottom-20 text-gray-800 font-semibold text-sm sm:text-base">
                    {{ formatMoney(store.liquidFunds * 0.98) }}
                </div>
                <img :src="curve" />
                <div
                    class="absolute sm:left-2 top-[100%] sm:top-auto sm:bottom-2 text-sm text-primary-600 font-semibold">
                    Too liquid
                </div>
                <div
                    class="absolute right-0 sm:right-2 top-[100%] sm:top-auto sm:bottom-2 text-sm text-primary-600 font-semibold">
                    Not liquid
                </div>
            </div>
        </div>
        <template v-slot:footer>
            <p class="text-center">
                <SecondaryButton block class="mt-2" @click="() => {
            customValue = recommendedAmount
            showCustomAmountSelection = true
        }">I'll set a custom amount </SecondaryButton>
            </p>
        </template>
    </QuestionnaireSection>
    <div class="w-full text-center">
        <button class="px-4 py-2 text-sm underline" @click="helpModalOpen = true">I need some help</button>
    </div>
    <ContactModal :open="helpModalOpen" @close="helpModalOpen = false" :name="`${store?.firstName} ${store?.lastName}`"
        :email="store?.email" :phone="`+1${store?.phone}`" calLink="revise/app-call" />

    <Modal :open="showCustomAmountSelection" title="Enter a custom premium amount"
        @close="showCustomAmountSelection = false"
        body="This is the first premium payment that will open your annuity contract, subject to carrier minimums."
        button2="Continue" :button2Disabled="customValue && customValue < 20000"
        @click:button2="() => { if (customValue && customValue < 20000) return; showCustomAmountSelection = false; showConfirmationModal = true; }">
        <div class="mt-4 mb-8">
            <RadioField :options="[{
            value: 'recommended', label: `${formatMoney(recommendedAmount)} <span class='
                bg-primary-100 text-primary-600 text-[0.7rem] font-semibold uppercase rounded-full px-2 py-0.5'>Recommended</span>` },
        { value: 'custom', label: 'Custom amount' }]" :value="customAmountSelection" name="customAmountSelection"
                @input="v => {
            customAmountSelection = v
            if (v === 'recommended') customValue = recommendedAmount
        }" />
            <div class="pl-6">
                <MoneyField :value="customValue" :disabled="customAmountSelection !== 'custom'"
                    @input="v => customValue = v" />
                <ErrorMessage v-if="customValue && customValue < 20000">You must fund $20,000 or
                    more.</ErrorMessage>
            </div>
        </div>
    </Modal>
    <Modal :open="showConfirmationModal"
        :title="`You are about to apply for an annuity in the amount of ${customAmountSelection == 'custom' ? formatMoney(customValue) : formatMoney(recommendedAmount)}.`"
        body="This is also known as the first premium payment. If you'd like to make additional monthly contributions, you can do so in your online dashboard after issuance of the policy."
        @close="closeConfirmationModal" button1="Cancel" button2="Continue" @click:button1="closeConfirmationModal"
        @click:button2="nextStep" :button1Loading="store.submitting">
    </Modal>
</template>

<script setup>
import QuestionnaireSection from '../questionnaireSections/QuestionnaireSection.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { event } from 'vue-gtag'
import { formatMoney } from '../../utils';
import curve from '../../assets/curve.svg';
import Modal from '../Modal.vue';
import ContactModal from '../ContactModal.vue';
import {
    SliderField,
    RadioField,
    MoneyField,
    ErrorMessage,
    SecondaryButton
} from '@reviseannuity/randolph-ui'
import { useRegistrationStore } from '../../stores/registrationStore';
import { updateUser } from '../../clients/user';

const store = useRegistrationStore()

const recommendedAmount = computed(() => Math.round(store.liquidFunds * 0.92))
const customAmountSelection = ref('recommendedAmount');
const showCustomAmountSelection = ref(false);
const customValue = ref(0);
const showConfirmationModal = ref(false)
const helpModalOpen = ref(false)

const formatSliderLabel = value => {
    return '$' + Intl.NumberFormat('en', { notation: 'compact' }).format(value)
}

const closeConfirmationModal = () => {
    showConfirmationModal.value = false
    customValue.value = 0
}

const ticks = [
    { position: 0, value: 22000, label: formatSliderLabel(22000) },
    { position: 50, value: 100000, label: formatSliderLabel(100000) },
    { position: 70, value: 300000, label: formatSliderLabel(300000) },
    { position: 82, value: 500000, label: formatSliderLabel(500000) },
    { position: 92, value: 1000000, label: formatSliderLabel(1000000) },
    { position: 100, value: 5000000, label: `${formatSliderLabel(5000000)}+` },
]

onMounted(() => {
    event('page_view', {
        page_title: 'Master App Registration - Investment Amount',
        page_location: window.location.href,
        page_path: window.location.pathname
    })

    if (store.liquidFunds < 22000) {
        store.setField('liquidFunds', 22000)
    } else if (store.liquidFunds > 5000000) {
        store.setField('liquidFunds', 5000000)
    }

    event('page_view', {
        page_title: 'Master App Questionnaire - Investment Input',
        page_location: window.location.href,
        page_path: window.location.pathname
    })
})

const nextStep = async () => {
    store.setField('investmentAmount', customAmountSelection.value == 'custom' ? customValue.value : recommendedAmount.value)

    await updateUser(store.auth0Id, {
        funding_amount: Number(store.investmentAmount)
    })

    store.setActiveStep('apply')
}
</script>