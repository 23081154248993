import { useRoute, useRouter } from 'vue-router';

export const useStep = (store) => {
    const route = useRoute()
    const router = useRouter()

    const currentStep = store.steps.find(step => route.path.includes(step.url))
    const stepIndex = store.steps.findIndex(step => route.path.includes(step.url))

    const nextStep = () => router.push(store.steps[stepIndex + 1].url)

    const prevStep = () => router.push(store.steps[stepIndex - 1].url)

    return { route, currentStep, stepIndex, nextStep, prevStep }
}
