<template>
    <div
        class="flex grow flex-col items-center lg:items-start lg:gap-y-5 overflow-y-auto border-b lg:border-r border-gray-200 bg-white px-4 pt-2 lg:px-6 lg:pb-4">
        <div class="lg:mt-4 flex lg:h-16 shrink-0 items-center justify-between lg:justify-start w-full">
            <img class="h-5 w-auto" src="../assets/logo.svg" alt="Revise Annuity Group logo" />
            <div class=" lg:fixed lg:top-0 lg:right-8 flex gap-2">
                <a href="tel:8552564477" v-if="showPhone"
                    class="bg-primary-200 flex items-center justify-center gap-1 px-3 py-2 lg:py-0.5 text-primary-700 rounded lg:rounded-t-none text-sm font-semibold">
                    <PhoneIcon class="inline-block h-4 lg:h-3" /> <span class="hidden lg:block">
                        (855) 256-4477
                    </span>
                </a>
                <button @click="helpModalOpen = true"
                    class="bg-primary-600 flex items-center justify-center gap-1 px-3 py-2 lg:py-0.5 text-white rounded lg:rounded-t-none text-sm font-semibold">
                    <LifebuoyIcon class="inline-block h-3 w-3" />
                    Need help?
                </button>
            </div>
        </div>
        <nav ref="container" class="w-full overflow-scroll no-scrollbar my-2 lg:my-0">
            <ol role="list" class="flex lg:flex-1 lg:flex-col gap-x-5 lg:gap-0 lg:gap-y-3">
                <SidebarStepLink @click="goToStep(step.url)" v-for="(step, i) in store.steps" :key="i" :url="step.url"
                    :name="step.name" :i="i" :active="route.path.includes(step.url)" />
            </ol>
        </nav>
        <div class="lg:mt-auto w-full hidden lg:block">
            <div class="lg:mb-8">
                <div class="items-center justify-between mb-2 flex">
                    <p class="text-xs text-gray-600 ">Application Progress ({{ Math.round(store.progress)
                        }}%)
                    </p>
                    <div class="flex items-center gap-1">
                        <span v-if="store.savingApplication" class="text-[0.5em] text-gray-600">Saving...</span>
                        <ArrowPathIcon v-if="store.savingApplication" class=" h-3 w-3 animate-spin text-gray-600" />
                        <CheckCircleIcon v-else class="h-4 w-4 text-primary-600" />
                    </div>
                </div>
                <ProgressBar :progress="store.progress" />
            </div>
            <p class="text-xs text-gray-400">ID: {{ store.applicationId || store.user.application[0] }}
                | <a href="https://getrevise.com/privacy-policy" class="text-primary-600 underline">Privacy Policy</a>
            </p>
        </div>
    </div>
    <ProgressBar :progress="store.progress" noRounding class="lg:hidden" />
    <div>
    </div>
    <ContactModal :open="helpModalOpen" @close="helpModalOpen = false"
        :birthdate="store?.personalInformation?.birthdate" :strategyName="store?.strategy?.name"
        :investmentAmount="store?.investmentAmount" :state="store?.personalInformation?.state"
        :name="store?.personalInformation?.name" :email="store?.personalInformation?.email"
        :phone="`+1${store?.personalInformation?.phone}`" calLink="revise/app-call" />
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useApplicationStore } from '../stores/applicationStore';
import { ArrowPathIcon, CheckCircleIcon, LifebuoyIcon, PhoneIcon } from '@heroicons/vue/20/solid';
import SidebarStepLink from './SidebarStepLink.vue';
import { ProgressBar } from '@reviseannuity/randolph-ui';
import { computed, ref } from 'vue';
import ContactModal from './ContactModal.vue';

const route = useRoute()
const router = useRouter()
const store = useApplicationStore()

const helpModalOpen = ref(false)
const container = ref(null)

router.afterEach(() => {
    if (!container.value) return
    const newVal = store?.steps.indexOf(store?.steps.find(step => route.path.includes(step.url)))
    container.value.scrollTo({
        left: container.value.scrollWidth * newVal / store.steps.length,
        behavior: 'smooth'
    });
})

const goToStep = (url) => {
    store.saveApplicationProgress()
    router.push(`/application/${url}`)
}

const showPhone = computed(() => {
    const currentTime = new Date()
    const currentHour = currentTime.getHours()
    return currentHour >= 9 && currentHour < 18
})
</script>